import React from "react";
import { motion } from "framer-motion";
import { FaLongArrowAltUp } from "react-icons/fa";
import Ourservices from "./sections/Ourservices.jsx";
import Card from "./sections/Ourmarketing.jsx";
import Imageslider from "./component/Imageslider.jsx";
import "./App.css";

import Featured from "./sections/Featured.jsx";
import Typewriter from "typewriter-effect";
import Contact from "./sections/Contact.jsx";
import { useState } from "react";
import Imagecard from "./sections/Imagecard.jsx";
import Imagecard2 from "./sections/Imagecard2.jsx";

//
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Visionmission from "./sections/Visionmission.jsx";
import Faq from "./sections/Faq.jsx";
import Whyus from "./sections/Whyus.jsx";
import Rankcheck from "./sections/Rankcheck.jsx";
import Carousel from "./sections/Carousel.jsx";
import Newcard from "./sections/Newcard.jsx";

function HomePage() {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  //

  const settings2 = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1890,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="flex-grow">
      <Imageslider />

      {/*  */}
      <div className="">
        <div className="text-left px-5 md:px-20 ">
          <div className="text-center pt-16">
            <div className="text-center border-l-4 px-5 border-blue-500">
              <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
                {/* <Typewriter
                  options={{
                    strings: [" Our Service|"],
                    autoStart: true,
                    loop: true,
                  }}
                /> */}
                Get The Digital Growth Edge
              </h1>

              <motion.h1
                className="text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                More Than Marketing, We're Growth Partners: Navi Mumbai's
                Leading Digital Marketing Agency
              </motion.h1>
            </div>
          </div>
        </div>
        {/*  */}
        
        <Imagecard2 />
        {/* <Imagecard /> */}

        {/* Rankcheckform */}
        <Rankcheck />
        {/* Rankcheckform */}
        {/*  */}
        <div className="text-left px-5 md:px-20 ">
          <div className="text-center pt-16">
            <div className="text-center border-l-4 px-5 border-blue-500">
              <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
                {/* <Typewriter
                  options={{
                    strings: [" Our Service"],
                    autoStart: true,
                    loop: true,
                  }}
                /> */}
                Our Services
              </h1>

              <motion.h1
                className="capitalize text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Experience Excellence: Take the next step towards success -
                Invest in our services now!
              </motion.h1>
            </div>
          </div>
        </div>
        <div className="flex-grow py-10">
          <div className=" justify-center gap-5 mt-5">
            <div className="flex flex-wrap justify-evenly  ">
            <Newcard />
             
            </div>
          </div>
        </div>
      </div>

      {/*  */}

      {/* vision mission */}
      <Visionmission />
      {/* vision mission */}

      <Featured />
     
     
    </div>
  );
}

export default HomePage;
