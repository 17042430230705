import React, { useState } from "react";

const ContactForm = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  let name, value;
  const handleInputs = (e) => {
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();
    const { name, email, phone, message } = user;
    const res = await fetch("/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
      }),
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      window.alert("invalied");
      console.log("invalied");
    } else {
      window.alert("send success");
      console.log("success");

      // history.push("/thankyou")
    }
  };
  return (
    <div className="  w-full ">
      <form method="POST" className="md:w-full sm:w-full  ">
        <h1 className="capitalize -mt-20 font-bold md:text-2xl lg:text-3xl text-xl md:text-center lg:text-center text-left py-5">
          How would you like to onboard <br /> Karn marketing warefare LLP ?
        </h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Your Name"
            name="name"
            value={user.name}
            onChange={handleInputs}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            placeholder="Your Email"
            name="email"
            value={user.email}
            onChange={handleInputs}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
            Phone No
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Your Phone No"
            name="phone"
            value={user.phone}
            onChange={handleInputs}
          />
        </div>
        {/* Add the message field */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
            Message
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="4"
            placeholder="Your Message"
            name="message"
            value={user.message}
            onChange={handleInputs}
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            onClick={PostData}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
