import React from "react";
import { motion } from "framer-motion";
  
function Marquee() {
  return (
    // <div  className="relative hidden md:block  mx-auto ">
    //   <div className="  text-gray-200  border-zinc-300 flex overflow-hidden whitespace-nowrap">
    //     <motion.h1
    //       initial={{ x: 0 }}
    //       animate={{ x: "-100%" }}
    //       transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
    //       className='lg:text-[2vw] leadding-none tracking-tighter  font-semibold uppercase  pr-20'
    //     >
    //       We Are KARN
    //     </motion.h1>
    //     <motion.h1
    //       initial={{ x: 0 }}
    //       animate={{ x: "-100%" }}
    //       transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
    //       className='lg:text-[2vw] leadding-none tracking-tighter  font-semibold uppercase  pr-20'
    //     >
    //       We Are KARN
    //     </motion.h1>
    //     <motion.h1
    //       initial={{ x: 0 }}
    //       animate={{ x: "-100%" }}
    //       transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
    //       className='lg:text-[2vw] leadding-none tracking-tighter  font-semibold uppercase  pr-20'
    //     >
    //       We Are KARN
    //     </motion.h1>
    //     <motion.h1
    //       initial={{ x: 0 }}
    //       animate={{ x: "-100%" }}
    //       transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
    //       className='lg:text-[2vw] leadding-none tracking-tighter  font-semibold uppercase  pr-20'
    //     >
    //       We Are KARN
    //     </motion.h1>
    //     <motion.h1
    //       initial={{ x: 0 }}
    //       animate={{ x: "-100%" }}
    //       transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
    //       className='lg:text-[2vw] leadding-none tracking-tighter  font-semibold uppercase  pr-20'
    //     >
    //       We Are KARN
    //     </motion.h1>

       
    //   </div>
    
    // </div>

    <div  className="relative hidden md:block  mx-auto w-[99vw]">
    <div className="  text-gray-200  border-zinc-300 flex overflow-hidden whitespace-nowrap">
      <motion.h1
        initial={{ x: 0 }}
        animate={{ x: "-50%" }}
        transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
        className='lg:text-[2vw] leadding-none tracking-tighter  font-semibold uppercase
       text-[#fff]
       bg-[#fb923c]
        pr-20'
      >
        Branding ➡ Website Development ➡ Social Media Marketing ➡ Search Engine Optimization ➡ Influencer Marketing ➡ PR Managment
      </motion.h1>
      
      <motion.h1
        initial={{ x: 0 }}
        animate={{ x: "-50%" }}
        transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
        className='lg:text-[2vw] leadding-none tracking-tighter  font-semibold uppercase
        text-[#fff]
        bg-[#fb923c]
        pr-20'
      >
        Branding ➡ Website Development ➡ Social Media Marketing ➡ Search Engine Optimization ➡ Influencer Marketing ➡ PR Managment
      </motion.h1>
     
    </div>
  
  </div>
  );
}

export default Marquee;
