import React from "react";

const SimpleProcess = () => {
  const radius = 50;
  const style = {
    width: `${radius * 2}px`,
    height: `${radius * 2}px`,
    borderRadius: radius,
    background: "",
    border: "1px solid black",
  };
  const style2 = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };
  return (
    <div className="w-full">
      {/* <p className="text-2xl font-bold xl:text-[1.5vw] leading-tight">Simple Process</p> */}
      <p className="text-5xl font-bold xl:text-[2vw] leading-tight capitalize">Website development process </p>
      <div className="flex mt-5 flex-col md:flex-row   md:mx-4    ">
        <div className="w-full p-5 ">
          <div className="flex flex-col items-center ">
            <div style={style} className=" text-5xl p-5">
              01
            </div>
            <div className="pt-5">
              <p className="font-bold"> Planning and Strategy</p>
              <p>
              This initial phase focuses on understanding the purpose of your website and who it's for. You'll define your target audience, set goals for the website

              </p>
            </div>
          </div>
        </div>
        <div className=" w-full h-full pt-20 hidden md:block">
          <img
            src="https://themazine.com/mr/Innov/assets/img/service-shap.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full p-5">
          <div className="flex flex-col items-center">
            <div style={style} className=" text-5xl p-5">
              02
            </div>
            <div className="pt-5">
              <p className="font-bold">Design/Production</p>
              <p>
              Design and Development Designing the visual identity of the website includes establishing a layout, choosing colors and fonts, and making sure the overall look is consistent

              </p>
            </div>
          </div>
        </div>
        <div className=" w-full h-full py-10 hidden md:block">
          <img
            src="https://themazine.com/mr/Innov/assets/img/service-shap-right.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full p-5">
          <div className="flex flex-col items-center">
            <div style={style} className=" text-5xl p-5">
              03
            </div>
            <div className="pt-5">
              <p className="font-bold">Development</p>
              <p>
              Launch and Refinement: Once the website is built, it's time to launch it to the world!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleProcess;
