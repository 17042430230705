import React from "react";
import { motion } from "framer-motion";
const Imagecard2 = () => {
  return (
    <div className="flex lg:flex-row flex-col w-full  mt-16 md:px-24">
      <div className=" w-full   ">
        <div className="flex justify-center">
          <img
            // src="https://wordpressriverthemes.com/htmltemp/pixlab/assets/images/about/about-1.jpg"
            src="/images/Professionaldm.jpg"
            alt=""
            className="h-full w-3/4 object-contain"
            style={{
              borderRadius: "300px",
            }}
          />
        </div>
      </div>
      <div className="w-full text-left mx-5 md:mx-0 md:mt-0 mt-9">
        <p className="text-2xl md:text-[2.5vw] font-bold xl:text-5xl">
          NAVI MUMBAI DIGITAL MARKETING AGENCY TO REACH MORE CUSTOMERS.
        </p>
        <p className="text-[#eb754d] md:text-[1.2vw] md:text-lg font-semibold my-2 capitalize xl:text-2xl">
          Professional Digital Marketing Agency to provide solutions
        </p>
        <p className="lg:text-[1.5vw] xl:text-[1.7vw] leading-tight">
          At KARN we are aware of how difficult it may be to establish a name
          for yourself in the tough digital market. We're not just another
          marketing agency; we're your strategic partner, meticulously crafting
          data-driven campaigns that deliver tangible results. 
          <p className="pt-5">Here's what sets us apart:</p>
        </p>

        <div
          className="pt-5 md:flex "
          // className="lg:items-start lg:flex lg:flex-wrap lg:pt-20 mx-auto lg:w-20 "
          // style={{ borderRadius: "50%" }}
        >
          <a className="" href="/about">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="
                  
                   uppercase mb-5 hover:bg-[#eb754d]
                   hover:text-white p-5 
                   rounded-lg font-bold text-[#eb754d] border
                   border-[#eb754d]
                   
                   "
              style={
                {
                  // width: "min(100%, 100vh)",
                  // height: "min(100%, 100vh)",
                }
              }
            >
              Read More
            </motion.button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Imagecard2;
