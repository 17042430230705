import React from "react";
import Imageslider from "./component/Imageslider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Mediacard from "./component/Mediacard";
import Offer from "./component/Offer";
import Maindirection from "./component/Maindirection";

const Media = () => {
  const settings2 = {
    // dots: true,
    // infinite: true,
    // speed: 500,
    slidesToShow: 4,
    // slidesToScroll: 1,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1890,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Imageslider />
      {/*  */}
      <div className="mt-20 px-5 md:px-20 flex flex-wrap lg:flex-row md:flex-row sm:flex-col text-left">
        <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink  ">
          <h1 className="text-6xl font-bold mb-4 text-[#fb923c] md:text-left text-center capitalize">
            Social media marketing Agency in Navi Mumbai
          </h1>
        </div>
        <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
          <p className=" lg:text-[1.5vw] text-black">
            Social media marketing is the method of promoting a service or
            product via numerous social media platforms. The success of the
            brand-new generation of social media platforms modified the way we
            socialize, engage, and keep. This additionally introduced new
            channels for digital advertising as nicely. You must have effective
            social media marketing techniques in the vicinity. It will assist
            together with your website’s ranking in online searches, assist with
            your online recognition, boost your core network, and permit you to
            construct new relationships. Free and Paid advertising campaigns on
            social media will intensively raise your enterprise. In trendy,
            there are methods to carry out a social media advertising campaign.
          </p>

          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
      Learn More
    </button> */}
        </div>
      </div>
      {/*  */}

      <div className="mt">
      
        <h1 className="text-5xl font-bold mt-6">Our Services</h1>

        <div className="mt-12 md:mx-24 px-8 ">
          <Slider {...settings2} className="slider w-full  ">
            <Mediacard
              path="/Branding"
              title="Facebook"
              description="Probably, this social network is known in the CIS, even those who have Internet access and some can not use. No joke, every day facebook.com site is visited by over 70 million people! And the vast majority of them - the Russian language."
              image="./social/fb.png"
              heading="Advertising in Facebook"
              service="Provided services"
              data1={["service", "Attracting participants", "Management group"]}
              data2={[
                "Drawing contests",
                "Live subscribers",
                "Communication with customers",
              ]}
            />
            <Mediacard
              path="/SEO"
              title="Instagram"
              description="We'll propel your website to the top of search engine results pages (SERPs) through strategic keyword research, on-page optimization, and link-building strategies. Let organic traffic become your digital infantry."
              image="./social/it.png"
              data1={["Design group", "Management group", "Live subscribers"]}
              data2={[
                "Attracting participants",
                "Drawing contests",
                "Communication with customers",
              ]}
            />
            <Mediacard
              path="./social/media"
              title="Twitter"
              description="Interact with your followers on the networks they use to communicate. We'll develop targeted social media campaigns that build brand loyalty, drive website traffic, and generate leads."
              image="./social/tw.png"
              data1={[
                "Attracting participants",
                "Management group",
                "Live subscribers",
              ]}
              data2={[
                "Design group",
                "Drawing contests",
                "Communication with customers",
              ]}
            />
            <Mediacard
              path="/influencer_marketing"
              title="Youtube"
              description="Influencer marketing leverages trusted online personalities to promote products to their engaged audience, fostering brand awareness and potentially boosting sales."
              image="./social/yt.png"
              data1={["service", "Attracting participants", "Management group"]}
              data2={[
                "Drawing contests",
                "Live subscribers",
                "Communication with customers",
              ]}
            />
          </Slider>
        </div>
      </div>
      <hr />
      <div className="my-20">
 
        <h1 className="text-5xl font-bold ">We offer</h1>
        <div className="my-12 md:mx-24 px-8">
          <Slider {...settings2} className="slider   w-full ">
            <Offer
              title="Deep Audience Insights and Targeting"
              description="We Go beyond demographics. Leverage social listening tools and analytics to understand the client's target audience on a deeper level."
              image="https://ck01708.tmweb.ru/demo/ultrasocial/images/works/w1.png"
              heading="Advertising in Facebook"
              service="Provided services"
            />
            <Offer
              title="Data-Driven Content Strategy"
              description="Move beyond generic content calendars. We Analyze what content performs best for the client's industry and target audience."
              image="https://ck01708.tmweb.ru/demo/ultrasocial/images/works/w2.png"
            />
            <Offer
              title="Cutting-edge technologies and Tools"
              description="We adopting the latest social media marketing technologies, like social listening platforms, influencer marketing tools, and content creation automation."
              image="https://ck01708.tmweb.ru/demo/ultrasocial/images/works/w3.png"
            />
            <Offer
              title="Transparency and Regular Reporting"
              description="Provide clients with in-depth, regular reports that showcase the impact of your social media campaigns"
              image="https://ck01708.tmweb.ru/demo/ultrasocial/images/works/w4.png"
            />
          </Slider>
        </div>
      </div>

      {/* add main direction section */}
      <Maindirection />

    
      {/*  */}

     
<div className="container  mx-auto md:px-6">

  <section className="my-32">
  
    <div className="container mx-auto text-center lg:text-left xl:px-32">
      <div className="grid items-center lg:grid-cols-2">
        <div className="mb-12 lg:mb-0">
          <div
            className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
            <h2 className="mb-8 text-3xl font-bold">Why Choose KARN Marketing Warfare LLP?</h2>
            <p className="mb-8 pb-2 text-neutral-500 dark:text-neutral-300 lg:pb-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. A
              soluta corporis voluptate ab error quam dolores doloremque,
              quae consectetur.
            </p>

            <div className="mx-auto mb-8 flex flex-col md:flex-row md:justify-around lg:justify-between">
              <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                  stroke="currentColor" className="mr-2 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Best team
              </p>

              <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                  stroke="currentColor" className="mr-2 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Best quality
              </p>

              <p className="mx-auto mb-2 flex items-center md:mx-0 lg:mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                  stroke="currentColor" className="mr-2 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Best experience
              </p>
            </div>

            <p className="mb-0 text-neutral-500 dark:text-neutral-300">
            KARN goes beyond basic posting. They'll develop a data-driven social media strategy tailored to your brand and target audience. This ensures your content resonates with the right people at the right time.KARN creates engaging social media campaigns that capture attention and drive results. They understand the power of storytelling and use it to build brand loyalty and advocacy. They track key metrics and analyze data to measure campaign effectiveness. This allows them to constantly refine your strategy and optimize your social media presence.
            </p>
          </div>
        </div>

        <div>
          <img src="https://img.freepik.com/free-photo/businessman-aiming-target-with-bow-arrow-isolated-gray-studio-wall_155003-29500.jpg?t=st=1711446471~exp=1711450071~hmac=ec37981a44dbf08b5029351f33ec78d02487b02324ae47098bda465d9a733778&w=740"
            className="w-full rounded-lg shadow-lg dark:shadow-black/20" alt="image" />
        </div>
      </div>
    </div>
   
  </section>
  
</div>



<div class="container  mx-auto md:px-6">

  <section class="mb-32">
    <div
      class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <div class="flex flex-wrap items-center">
        <div class="hidden shrink-0 grow-0 basis-auto lg:flex md:w-full lg:w-6/12 xl:w-4/12">
          <img src="https://img.freepik.com/free-vector/vacant-job-promo-with-join-us-message_52683-61756.jpg?t=st=1711446800~exp=1711450400~hmac=8bc2ad491049822a237e9264e66aad2cb0be87d7db13c57f7316831e17eca0d9&w=826" alt="Trendy Pants and Shoes"
            class="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" />
        </div>
        <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
          <div class="px-6 py-12 md:px-12">
            <h2 class="mb-4 text-2xl font-bold">
            Ready to Join the KARN Squad?
            </h2>
      
          
            <ul
            className="mb-6 text-neutral-500 dark:text-neutral-300 text-left "
            style={{ listStyleType: "disc" }}
          >
            <li>
              We're obsessed with empowering agencies like yours to overcome the
              virtual battlefield. If you are ready to take your social media
              presence to the next level, contact KARN Marketing Warfare LLP
              nowadays for a loose session. Together, we will craft a winning
              strategy and unleash the whole ability of your brand in the
              ever-evolving social media landscape.
            </li>
            <li>Follow us on social media!</li>
            <li>
              Stay related to contemporary advertising insights, industry
              developments, and a healthful dose of KARN humor by way of
              following us to your favored social media systems.
            </li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>

      {/*  */}
    </>
  );
};

export default Media;
