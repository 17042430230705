import React from "react";

const WhatOffering = () => {
  return (
    <div className="mt-12">
      <div>
      

        <p className="text-[#0f0f0f] text-5xl font-bold xl:text-[2vw] leading-tight capitalize">
          Services we’re offering to our customers.
        </p>
      </div>
      <div className=" w-full mx-auto">
        <div className="flex md:flex-row flex-col mx-12 my-12 gap-5 ">
          <div className="relative w-full py-10 bg-[#f8f8f8] hover:bg-[#fb923c] hover:text-white  px-5">
            <p className="absolute font-extrabold text-9xl w-full text-gray-100  ">
              1
            </p>

            <h1 className="pt-32 font-bold text-2xl text-left  xl:text-[1.5vw] leading-tight">
              Responsive Website Design
            </h1>
            <p className="pt-10  text-xl text-left  xl:text-[1.5vw] leading-tight">
              Our experts will create exceptional mobile-friendly websites that
              will not only keep your customers engaged but also retain them.
            </p>
          </div>
          <div className="relative w-full py-10 bg-[#f8f8f8] hover:bg-[#fb923c] hover:text-white  px-5">
            <p className="absolute font-extrabold text-9xl    w-full text-gray-100">
              2
            </p>
            <h1 className="pt-32 font-bold text-2xl text-left  xl:text-[1.5vw] leading-tight ">
              Ecommerce Website Design
            </h1>
            <p className="pt-10  text-xl text-left  xl:text-[1.5vw] leading-tight ">
              Through our creative eCommerce designs, we ensure that a user can
              easily locate a product, add it to the cart, and complete the
              transaction with a breeze
            </p>
          </div>
          <div className="relative w-full py-10 bg-[#f8f8f8] hover:bg-[#fb923c] hover:text-white  px-5">
            <p className="absolute font-extrabold text-9xl w-full text-gray-100">
              3
            </p>
            <h1 className="pt-32 font-bold text-2xl text-left  xl:text-[1.5vw] leading-tight ">
              Custom Website Design
            </h1>
            <p className="pt-10  text-xl text-left  xl:text-[1.5vw] leading-tight ">
              We custom design banners and layouts for your website that catches
              eyes
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatOffering;
