import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const ENQUIRE = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible);
  };

  return (
    <>
      <button className="
      -rotate-90 -mr-10 opacity-100 
      fixed top-[50vh] right-4 bg-[#fb923c] text-white  font-bold py-2 px-2 rounded shadow z-40">
        <Link to="./contact">ENQUIRE NOW</Link>
      </button>
    </>
  );
};

export default ENQUIRE;
