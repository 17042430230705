import React from "react";
import Contact from "./sections/Contact";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import Imageslider from "./component/Imageslider";

const ContactPage = () => {
  return (
    <>
     <Imageslider />
   
    <div>
      <Contact />
      <div className="text-left px-5 md:px-20">
        <div className=" pt-10 ">
          <div className="text-center border-l-4 px-5 border-blue-500">
            <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
              {/* Contact */}
              {/* <Typewriter
                options={{
                  strings: ["Map"],
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              Map
            </h1>

            <motion.p
              className="text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              onAnimationComplete={() => {
                const heading = document.querySelector("h1");
                heading.classList.add("locomotive-text-enter");
              }}
            >
             Find us on map
            </motion.p>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-20 pb-28 w-full ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.8357008693906!2d73.00294577607013!3d19.11486238209765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf534f544679%3A0x7308a20923729767!2sAmbe%20Prerna!5e0!3m2!1sen!2sin!4v1709029693464!5m2!1sen!2sin"
          width="1000"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    </>
  );
};

export default ContactPage;
