import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import Imageslider from "./component/Imageslider";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
const PR = () => {
  const settings2 = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1890,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Imageslider />
      <div className="mt-20 md:px-24 mx-auto lg:w-full">
        <div className="flex md:flex-row flex-col   mx-5 justify-between lg:gap-32">
          <div className="w-full md:w-1/2  flex justify-evenly ">
            <h1 className="capitalize text-5xl text-left">
              Engage the leading Public Relations agency in Navi Mumbai.
            </h1>
          </div>
          <div className="w-full md:w-1/2  text-left justify-evenly ">
            <p className="lg:text-[1.5vw] text-gray-500">
              PR in the literal experience of the term manner Public Relations,
              the artwork of dealing with and controlling the discharge and
              spread of statistics between an person or an organisation and the
              common public, With its services like Brand positioning, Media,
              Strategic counseling, Digital have an impact on, Crisis communique
              and Reputation control, a PR agency in Navi Mumbai guarantees all
              the help had to pitch in your business inside the marketplace,
              promotes it across nearby borders and establishes its reign within
              the minds of the centered clients.
            </p>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="mt-20 w-full mx-auto  bg-black text-white py-10">
        <div className="flex  lg:flex-col flex-col  md:mx-14 mx-5 justify-between lg:gap-10">
          <div className="w-full lg:w-full  flex justify-evenly  my-auto">
            <div>
              <h1 className="capitalize text-5xl text-left  ">
                What are the Importance of PR services in a business?
              </h1>
            </div>
          </div>
          <div className="w-full md:w-full text-left lg:mt-0 mt-10 justify-evenly border-2 md:p-5 rounded-2xl">
            <div className=" flex justify-center  w-full   md:flex-row flex-col ">
              <div className="w-full">
                <div className=" h-[50vh]">
                  <div className="">
                    <motion.div
                      className="p-6  sm:w-80 mx-auto group"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex flex-col">
                        <img
                          src="https://www.srvmedia.com/wp-content/uploads/2023/10/Group-8.png"
                          className="object-cover mx-auto mb-4 rounded-t-lg"
                        />
                        <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                          Builds Brand Credibility and Trust
                        </h2>
                        <p className="text-[#fff]">
                          PR helps establish your brand as a trustworthy and
                          reliable entity through positive media coverage and
                          strategic communication.
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <motion.div
                      className="p-6  sm:w-80 mx-auto group"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex flex-col">
                        <img
                          src="https://www.srvmedia.com/wp-content/uploads/2023/10/Group-5394.png"
                          className="object-cover mx-auto mb-4 rounded-t-lg"
                        />
                        <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                          Increases Brand Awareness and Visibility
                        </h2>
                        <p className="text-[#fff]">
                          PR gets your brand name out there, reaching a wider
                          audience through press releases, media outreach, and
                          social media engagement.
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="w-full ">
                <div className=" h-[50vh]">
                  <div className="">
                    <motion.div
                      className="p-6  sm:w-80 mx-auto group"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex flex-col">
                        <img
                          src="https://www.srvmedia.com/wp-content/uploads/2023/10/Group-9.png"
                          className="object-cover mx-auto mb-4 rounded-t-lg"
                        />
                        <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                          Boosts Sales and Leads
                        </h2>
                        <p className="text-[#fff]">
                          By generating positive press and building brand trust,
                          PR can indirectly lead to increased sales and
                          qualified leads.
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <motion.div
                      className="p-6  sm:w-80 mx-auto group"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex flex-col">
                        <img
                          src="https://www.srvmedia.com/wp-content/uploads/2023/10/Group-11.png"
                          className="object-cover mx-auto mb-4 rounded-t-lg"
                        />
                        <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                          Shapes Public Perception
                        </h2>
                        <p className="text-[#fff]">
                          PR allows you to control the narrative around your
                          brand and influence public opinion.
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="w-full ">
                <div className=" h-[50vh]">
                  <div className="">
                    <motion.div
                      className="p-6  sm:w-80 mx-auto group"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex flex-col">
                        <img
                          src="https://www.srvmedia.com/wp-content/uploads/2023/10/Group-9.png"
                          className="object-cover mx-auto mb-4 rounded-t-lg"
                        />
                        <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                          Improves Relationships with Stakeholders
                        </h2>
                        <p className="text-[#fff]">
                          PR helps you build strong relationships with
                          investors, partners, customers, and employees.
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <motion.div
                      className="p-6  sm:w-80 mx-auto group"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex flex-col">
                        <img
                          src="https://www.srvmedia.com/wp-content/uploads/2023/10/Group-11.png"
                          className="object-cover mx-auto mb-4 rounded-t-lg"
                        />
                        <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                          Enhances Online Presence
                        </h2>
                        <p className="text-[#fff]">
                          PR efforts often go hand-in-hand with digital
                          marketing strategies, improving your website's SEO and
                          overall online visibility.
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" w-full mx-auto  bg-black text-white py-10">
        <div className="flex  md:flex-row flex-col  md:mx-14 mx-5 justify-between lg:gap-32">
          <div className="w-full md:w-1/2  flex justify-evenly  ">
            <div className="text-left ">
              <h1 className="capitalize text-5xl text-left my-auto ">
                Top-Notch PR Services We Offer
              </h1>

              <p className="mt-5 p-5 border-2">
                Organic Press Release Writing and Distribution
              </p>

              <p className="p-5 border-2">Online Reputation Management</p>

              <p className="p-5 border-2">Brand Stories & Brand Launch</p>

              <p className="p-5 border-2">Brand Building with PR</p>

              <p className="p-5 border-2">Crisis Management</p>

              <p className="p-5 border-2">Influencer Marketing</p>

              <p className="p-5 border-2">Spokesperson Profiling</p>

              <p className="p-5 border-2">Podcast</p>

              <p className="p-5 border-2">Webinars</p>

              <p className="p-5 border-2">TedX</p>
            </div>
          </div>
          <div className="w-full md:w-1/2  ">
            <img
              src="https://www.srvmedia.com/wp-content/uploads/2023/10/Group-5397-e1696228084172-1200x845.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="w-full mx-auto  bg-black text-white py-10">
        <div className="bg-white mx-auto text-black w-2/3 py-5 rounded-md capitalize">
         <p className="text-3xl">Activate Discussions and encourage Achievement!</p>
        <button className="bg-[#fb923c] text-white p-2 my-2 rounded-md"><a href="/contact">Contact Us Today To Discuss</a></button>
        </div>
      </div>
    </>
  );
};

export default PR;
