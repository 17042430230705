import React, { useEffect, useState } from "react";
import "./custom.css";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
const Rankcheck = () => {
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  //

  const [user, setUser] = useState({
    url: "",
    phone: "",
    email: "",
  });

  
const [isSubmitted, setIsSubmitted] = useState(false);

  let name, value;
  const handleInputs = (e) => {
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();
    const { url, email, phone } = user;
    const res = await fetch("/performance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url,
        phone,
        email,
      }),
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      window.alert("invalied");
      console.log("invalied");
    } else {
      window.alert("send success");
      console.log("success");
      setIsSubmitted(true);
      // history.push("/thankyou")
    }
   
  };
  useEffect(() => {
    if (isSubmitted) {
      setUser({
        url: "",
        phone: "",
        email: "",
      });
      setIsSubmitted(false); // reset isSubmitted to false
    }
  }, [isSubmitted]);
  

  //

  



  return (
    <>
      <div className="text-left px-5 md:px-20 ">
        <div className="text-center pt-16">
          <div className="text-center border-l-4 px-5 border-blue-500">
            <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
              {/* <Typewriter
                options={{
                  strings: [" Website Performance"],
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              Website Performance
            </h1>

            <motion.h1
              className="text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Check Your Website Performance
            </motion.h1>
          </div>
        </div>
      </div>

      <h1 className="text-5xl pt-16 capitalize">
        Check your Website <span className="text-[#fb923c]">Performance</span>
      </h1>
      <form
      
        className="rankcheck-form my-16 items-center py-20 flex flex-col bg-[#717574] "
      >
        <form method="POST" className="labels md:flex-row flex flex-col  justify-between  gap-6 p-5  ">
          <input
            type="url"
            placeholder="Website URL :"
            id="website-url"
            name="url"
            value={user.url}
            onChange={handleInputs}
            required
            className="rounded-l-3xl p-10   bg-transparent  font-sans font-normal placeholder-white border-2  "
          />

          <input
            type="tel"
            placeholder="Mobile No :"
            id="mobile-number"
            name="phone"
            value={user.phone}
            onChange={handleInputs}
            required
            className="rounded-l-3xl p-10   bg-transparent placeholder-white font-sans font-normal  border-2  "
          />

          <input
            placeholder="Your Email :"
            type="email"
            id="email"
            name="email"
            value={user.email}
            onChange={handleInputs}
            required
            className="rounded-l-3xl p-10   bg-transparent placeholder-white font-sans font-normal  border-2  "
          />
          <div className="flex items-center justify-center">
            <button
              className="bg-[#fb923c]
              md:rounded-r-3xl text-xl
              hover:bg-[#fcae6e] text-white font-bold p-10 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              onClick={PostData}
            >
              Submit
            </button>
          </div>
        </form>

        
        <br />
        
      </form>
    </>
  );
};



export default Rankcheck;
