import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import ContactForm from "../component/ContactForm";
const Contact = ({ closeform }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const formRef = useRef(null);
  // const history = useHistory()
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  let name, value;
  const handleInputs = (e) => {
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();
    const { name, email, phone, message } = user;
    const res = await fetch("/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
      }),
    });
    const data = await res.json();
    if (data.status === 422 || !data) {
      window.alert("invalied");
      console.log("invalied");
    } else {
      window.alert("send success");
      console.log("success");

      // history.push("/thankyou")
    }
  };
  return (
    <>
      <div className="text-left px-5 md:px-20">
        <div className=" pt-10 ">
          <div className="text-center border-l-4 px-5 border-blue-500">
            <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
              {/* Contact */}
              {/* <Typewriter
                options={{
                  strings: ["Contact"],
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              Contact
            </h1>

            <motion.p
              className="capitalize text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              onAnimationComplete={() => {
                const heading = document.querySelector("h1");
                heading.classList.add("locomotive-text-enter");
              }}
            >
              Join with us to build the future of web development!
            </motion.p>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap md:mx-24 px-4 mt-16">
        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 ">
          <img
            className="h-1/2 max-w-full  rounded-2xl"
            src="/images/contactus.jfif"
            alt="image description"
          />
          {/* text */}
          <div className="pt-8 text-left">
            <p className="lg:text-[2vw] text-3xl font-bold capitalize">
              Digital marketing agency in navi mumbai
            </p>
            <p className="lg:text-[1.2vw] text-2xl pt-5">
              KARN Marketing Warfare LLP is a leading Digital Marketing Agency
              in Navi Mumbai! We specialize in handing over top-notch digital
              marketing offerings tailored to elevate your online presence and
              decorate your enterprise to new heights.
            </p>
          </div>
        </div>
        <div className="w-full  md:w-1/2  mb-4    ">
          <main className=" w-full flex flex-col  items-center md:ml-4 pb-20  ">
            <div className=" w-full py-3  sm:mx-auto ">
              <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                <div className="flex w-full gap-6 ">
                  <ContactForm />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Contact;

// import React, { useState } from "react";
// import axios from "axios";
// // import useHistory from "react-router-dom";
// const Contact = () => {
//   // const history = useHistory()
//   const [user, setUser] = useState({
//     name:"",email:"",phone:"",message:""
//   })

//   let name, value
//   const handleInputs =(e)=>{
//     console.log(e);
//     name= e.target.name;
//     value= e.target.value;
//     setUser({...user,[name]:value})
//   }

// const PostData= async(e)=>{
// e.preventDefault();
// const { name, email, phone, message } = user;
// const res =await fetch("/contact",{
//   method:'POST',
//   headers:{
//     'Content-Type':'application/json'
//   },
//   body:JSON.stringify({
//     name,email,phone,message
//   })
// })
// const data = await res.json()
// if(data.status === 422 || !data){
//   window.alert("invalied")
//   console.log('invalied');

// }else{
//   window.alert("send success")
//   console.log('success');

//   // history.push("/thankyou")
// }
// }

//   return (
//     <>
//       <div className="container mx-auto px-4 py-10">
//         <div className="w-full md:w-1/2">
//           <div className="flex flex-col justify-center items-center">
//             <h1 className="text-4xl font-bold text-center md:text-left">
//               Contact Us
//             </h1>
//             <p className="lg:text-[1.2vw] pt-5">
//               KARN Marketing Warfare LLP is a leading Digital Marketing Agency
//               in Navi Mumbai! We specialize in handing over top-notch digital
//               marketing offerings tailored to elevate your online presence and
//               decorate your enterprise to new heights.
//             </p>
//           </div>
//         </div>
//         <div className="w-full md:w-1/2 mb-4">
//           <main className="w-full flex flex-col items-center md:ml-4 pb-20">
//             <div className="w-full py-3 sm:mx-auto">
//               <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
//                 <div className="flex w-full gap-6">
//                   <form method="POST" className="md:w-full sm:w-full" >
//                     <h1 className="capitalize -mt-20 font-bold md:text-2xl lg:text-3xl text-xl md:text-center lg:text-center text-left py-5">
//                       How would you like to onboard <br /> Karn marketing
//                       warefare LLP ?
//                     </h1>
//                     <div className="mb-4">
//                       <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
//                         Name
//                       </label>
//                       <input
//                         className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                         type="text"
//                         placeholder="Your Name"
//                         name="name"
//                         value={user.name}
//                         onChange={handleInputs}
//                       />
//                     </div>
//                     <div className="mb-4">
//                       <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
//                         Email
//                       </label>
//                       <input
//                         className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                         type="email"
//                         placeholder="Your Email"
//                         name="email"
//                       value={user.email}
//                       onChange={handleInputs}
//                       />
//                     </div>
//                     <div className="mb-4">
//                       <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
//                         Phone No
//                       </label>
//                       <input
//                         className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                         type="number"
//                         placeholder="Your Phone No"
//                         name="phone"
//                         value={user.phone}
//                         onChange={handleInputs}
//                       />
//                     </div>
//                     {/* Add the message field */}
//                     <div className="mb-4">
//                       <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
//                         Message
//                       </label>
//                       <textarea
//                         className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                         rows="4"
//                         placeholder="Your Message"
//                         name="message"
//                         value={user.message}
//                         onChange={handleInputs}
//                       />
//                     </div>
//                     <div className="flex items-center justify-center">
//                       <button
//                         className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//                         type="submit"
//                         onClick={PostData}
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </main>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Contact;
