import React, { useState } from "react";
import { motion, useDragControls } from "framer-motion";
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";
const Ourservices = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      <div
        // data-scroll
        // data-scroll-section
        // data-scroll-speed=".3"
        // className="  pt-10 "
      >
       

        <div className="px-5 md:px-20 flex flex-wrap lg:flex-row md:flex-row flex-col-reverse text-left">
          <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
            {/* <h1 className="text-2xl font-bold mb-4 text-[#fead31] leading-tight md:text-[3vw]">
              ABOUT US
            </h1> */}
            <p className=" text-gray-500 md:text-[1.2vw] text-lg xl:text-[2vw] leading-tight">
              At KARN, we forge long-term partnerships, not just one-off
              campaigns. We take the time to understand your business goals and
              challenges and become an extension of your marketing team. You will receive frequent updates and strategic suggestions from your dedicated account manager, who will keep you informed.

            </p>
         
            <div className=" w-full flex m-0">
                <Link to="/contact">
                  <button
                    className="
              items-center  p-5 
              border-2 mt-5 text-[#fb923c] 
              border-[#fb923c] hover:text-white rounded-xl 
              hover:bg-[#fb923c]  text-xl font-bold capitalize
              "
                  >
                      Schedule a free consultation
                  </button>
                </Link>
              </div>
            
          </div>
          <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink ">
            <img
              // src="/logos/blacklogo.png"
              src="https://img.freepik.com/free-photo/advertise-communication-digital-marketing-business-concept_53876-133825.jpg?t=st=1711019608~exp=1711023208~hmac=048aead9704db2ba30f5bc5b3ff26ef8a0423c4ecee7a5c07a52bee65bb8dbbd&w=826"
              alt="Image 1"
              className="w-full h-full object-contain"
              onLoad={() => setIsLoaded(true)}
              style={{
                opacity: isLoaded ? 1 : 0,
                transition: "opacity 1s ease-in-out",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ourservices;
