import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";
import { motion,  AnimatePresence } from "framer-motion";

const Imageslider = () => {
  const slides = [
    // {
    //   image:
    //     "https://learnsure.ai/wp-content/uploads/2022/07/Digital-marketing-the-rocket-fuel-for-growth-in-the-insurance-business-1.svg",
    //   alt: "image 1",
    //   // text: "From Leads To Loyalty From Leads To Loyalty",
    //   // btntext: "Learn more",
    //   btnurl: "/services",
    // },
    // {
    //   image:
    //     "https://learn.g2.com/hubfs/iStock-1146710375.jpg",
    //   alt: "image 2",
    //   // text: "From Leads To Loyalty From Leads To Loyalty From Leads To Loyalty",
    //   // btntext: "Learn more",
    //   btnurl: "/services",
    // },
    {
      image:
        "https://images.pexels.com/photos/16229745/pexels-photo-16229745/free-photo-of-social-media-apps-on-smartphone.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      alt: "image 3",
      // text: "From Leads To Loyalty",
      // btntext: "Learn more",
      btnurl: "/services",
    },
    {
      image:
        "https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=600",
      alt: "image 3",
      // text: "From Leads To Loyalty",
      // btntext: "Learn more",
      btnurl: "/services",
    },
    {
      image:
        "https://images.pexels.com/photos/6476367/pexels-photo-6476367.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      alt: "image 3",
      // text: "From Leads To Loyalty",
      // btntext: "Learn more",
      btnurl: "/services",
    },
  ];
  const [currentSlide, setCurrentSlide] = React.useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); 
  
    return () => clearInterval(interval);
  }, [slides.length]);
  
  return (
    <Carousel 
    showThumbs={false}
    showStatus={false}
    infiniteLoop
    interval={1000}
    selectedItem={currentSlide}
    > 
       {slides.map((slide, index) => (
        <div key={index}  className="slide">
          <img
            src={slide.image}
            alt={slide.alt}
            // className="h-[500px] w-full object-cover opacity-900"
            className="lg:h-[100vh] h-[50vh] w-full object-cover opacity-900"
          />

          <motion.p
            // initial={{ x: 0 }}
            // animate={{ x: "-100%" }}
            // transition={{ ease: "linear", repeat: Infinity, duration: 10 }}
            className="text1"
            animate={{ transform: "translateX(5px)" }}
            transformTemplate={({ x, rotate }) =>
              `rotate(${10}deg) -translateX(${900}px)`
            }
          >
            {slide.text}
          </motion.p>

          <motion.div
            animate={{ transform: "translateX(0px)" }}
            transformTemplate={({ x, rotate }) =>
              `rotate(${10}deg) translateX(${900}px)`
            }
            className="mybtn "
          >
            {/* <button className="btntext ">
              <a  href={slide.btnurl}>{slide.btntext}</a>
            </button> */}
          </motion.div>
        </div>
      ))}
    </Carousel>
  );
};

export default Imageslider;
