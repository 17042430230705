import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Form from "./Form";
import "./styles.css";

const Dropdown = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState("/");
  const [isServicesDropdownVisible, setIsServicesDropdownVisible] =
    useState(false);
  const dropdownRef = useRef(null);

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible);
  };

  const handleLinkClick = (page) => {
    setCurrentPage(page);
    setIsMenuVisible(false);
  };

  const toggleServicesDropdown = () => {
    console.log(dropdownRef.current);
    setIsServicesDropdownVisible(!isServicesDropdownVisible);
  };

  // scroll bg transperent
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClassName = scrollPosition > 0 ? "bg-white" : "bg-transparent ";
  const navbarTextClassName =
    scrollPosition > 0 ? "text-gray-800" : "text-gray-500 ";
  const navbarImgSrc =
    scrollPosition > 0
      ? "https://karnllp.files.wordpress.com/2023/10/cropped-karn-logo.png"
      : "https://api.asm.skype.com/v1/objects/0-sa-d5-c196045a67de7c3132fda5c9c2398fd5/views/imgpsh_fullsize_anim ";
  const navbarImgClass = scrollPosition > 0 ? "h-16 " : "h-36";

  //

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsServicesDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <>
      <div>
        <button
          className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
            currentPage === "/website_development || /Branding" ? "bg-[#fb923c] text-white" : ""
          }`}
          // onClick={toggleServicesDropdown}
          onMouseEnter={toggleServicesDropdown}
          ref={dropdownRef}
        >
          Services
        </button>

        {isServicesDropdownVisible && (
          <div className="text-left absolute mt-2 w-58 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="services-menu"
            >
              <a
                href="/Branding"
                className="block ${navbarTextClassName} hover:text-white hover:bg-[#fcae6e] px-4 py-2 text-lg"
                role="menuitem"
              >
                Branding
              </a>
              <a
                href="/website_development"
                className="block ${navbarTextClassName} hover:text-white hover:bg-[#fcae6e] px-4 py-2 text-lg"
                role="menuitem"
              >
                Website Development
              </a>
              <a
                href="/media"
                className="block ${navbarTextClassName} hover:text-white hover:bg-[#fcae6e] px-4 py-2 text-lg"
                role="menuitem"
              >
                Social Media Marketing
              </a>
              <a
                href="/SEO"
                className="block ${navbarTextClassName} hover:text-white hover:bg-[#fcae6e] px-4 py-2 text-lg"
                role="menuitem"
              >
                SEO
              </a>
              <a
                href="/influencer_marketing"
                className="block ${navbarTextClassName} hover:text-white hover:bg-[#fcae6e] px-4 py-2 text-lg"
                role="menuitem"
              >
                Influencer Marketing
              </a>
              <a
                href="/PR"
                className="block ${navbarTextClassName} hover:text-white hover:bg-[#fcae6e] px-4 py-2 text-lg"
                role="menuitem"
              >
                PR Managment
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
