import React, { useRef } from "react";
import { motion, useScroll } from "framer-motion";

import Typewriter from "typewriter-effect";
import Imageslider from "./component/Imageslider";
import Servicecards from "./component/Servicecards";
const ServicesPage = () => {
  const ref = useRef(null);
  const {scrollYProgress}=useScroll({
    target:'',
    offset:["0 1", "1.33 1"],
  })
  return (
    <>
      <Imageslider />

      <Servicecards />

    
    </>
  );
};

export default ServicesPage;
