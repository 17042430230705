// import React, { useState } from "react";

// const FaqAccordion = () => {
//   const [expandedIndex, setExpandedIndex] = useState();

//   const faqData = [
//     {
//       question:
//         "How can a digital marketing agency in Navi Mumbai help my business grow?",
//       answer:
//         "Engaging the services of a digital marketing agency in Navi Mumbai can significantly contribute to the growth of your business in several ways. Firstly, they possess the expertise and resources to develop and execute targeted digital marketing strategies tailored to your business objectives and target audience. This can lead to increased brand visibility, website traffic, and ultimately, higher conversion rates. Furthermore, these agencies stay updated with the latest trends and algorithms in digital marketing platforms, ensuring that your business remains competitive in the online landscape. Additionally, their analytics and reporting capabilities enable them to track and analyze the performance of marketing campaigns, allowing for continuous optimization and improvement.",
//     },
//     {
//       question:
//         "What factors should I consider when choosing a social media marketing agency in Navi Mumbai?",
//       answer:
//         "When selecting a social media marketing agency in Navi Mumbai, it's essential to consider several factors to ensure you find the right fit for your business:Experience and expertise: Look for an agency with a proven track record of success in social media marketing, particularly within your industry. Consider their experience, case studies, and client testimonials.Services offered: Evaluate the range of services offered by the agency and ensure they align with your business goals and objectives. Consider whether they provide a comprehensive suite of services or specialize in specific areas. Communication and collaboration: Choose an agency that values clear communication and collaboration. Ensure they understand your business objectives and are willing to work closely with you to achieve them.",
//     },
//     {
//       question:
//         "How long does it take to see results from SEO efforts by an SEO company in Navi Mumbai?",
//       answer:
//         "The timeline for seeing results from SEO efforts by an SEO company in Navi Mumbai can vary depending on a variety of factors, including the competitiveness of your industry, the current state of your website, and the specific strategies employed by the SEO company. In general, however, it can take several months to start seeing significant improvements in your website's ranking and organic traffic. SEO is a long-term investment, and it often requires ongoing effort and monitoring to maintain and improve your website's performance over time.",
//     },
//     {
//       question:
//         "What is influencer marketing and why is it important for businesses in Navi Mumbai?",
//       answer:
//         "Influencer marketing is a form of marketing where businesses collaborate with influential individuals on social media platforms to promote their products or services. In Navi Mumbai, influencer marketing holds significant importance due to its ability to reach a targeted audience effectively. By leveraging the credibility and reach of influencers, businesses can enhance brand visibility, credibility, and engagement within the local market.",
//     },
//     {
//       question: "What Role Does Public Relations Play in Business?",
//       answer:
//         "Public relations plays a crucial role in business by helping to build and maintain a positive reputation, enhancing credibility, and fostering trust among stakeholders. It acts as a bridge between the organization and its target audience, facilitating effective communication and engagement. PR efforts contribute to brand awareness, customer loyalty, and ultimately, business growth. Additionally, in times of crisis or controversy, PR professionals play a vital role in managing reputation damage and mitigating negative publicity.",
//     },
//   ];

//   return (
//     <div className="faq-accordion md:mx-24 mx-7 text-left">
//       {faqData.map((faq, index) => (
//         <details
//           key={index}
//           onClick={() => {
//             if (expandedIndex === index) {
//               requestAnimationFrame(() => {
//                 setExpandedIndex(null);
//               });
//             } else {
//               setExpandedIndex(index);
//             }
//           }}
//           className="py-4"
//         >
//           <summary className="flex justify-start items-center gap-3 cursor-pointer">

//           <span
//               className={`transform transition-transform duration-300 ${
//                 expandedIndex === index ? "rotate-180" : ""
//               }`}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//                 fill="currentColor"
//                 className="w-5 h-5"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//             </span>
//             <h2 className="text-lg font-medium">{faq.question}</h2>

//           </summary>
//           <div
//             className={`px-6 pt-4 pb-2 ${
//               expandedIndex !== index ? "hidden" : ""
//             }`}
//           >
//             <p className="text-gray-700 -mx-7">{faq.answer}</p>
//           </div>
//         </details>
//       ))}
//     </div>
//   );
// };

// export default FaqAccordion;

import React, { useState } from "react";
import { Accordion } from "flowbite-react";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import { FiMinus, FiPlus } from "react-icons/fi";
import "./custom.css";
import Imageslider from "../component/Imageslider";
const Faq = () => {
  const faqData = [
    {
      question:
        "How can a digital marketing agency in Navi Mumbai help my business grow?",
      answer:
        "Engaging the services of a digital marketing agency in Navi Mumbai can significantly contribute to the growth of your business in several ways. Firstly, they possess the expertise and resources to develop and execute targeted digital marketing strategies tailored to your business objectives and target audience. This can lead to increased brand visibility, website traffic, and ultimately, higher conversion rates. Furthermore, these agencies stay updated with the latest trends and algorithms in digital marketing platforms, ensuring that your business remains competitive in the online landscape. Additionally, their analytics and reporting capabilities enable them to track and analyze the performance of marketing campaigns, allowing for continuous optimization and improvement.",
    },
    {
      question:
        "What factors should I consider when choosing a social media marketing agency in Navi Mumbai?",
      answer:
        "When selecting a social media marketing agency in Navi Mumbai, it's essential to consider several factors to ensure you find the right fit for your business:Experience and expertise: Look for an agency with a proven track record of success in social media marketing, particularly within your industry. Consider their experience, case studies, and client testimonials.Services offered: Evaluate the range of services offered by the agency and ensure they align with your business goals and objectives. Consider whether they provide a comprehensive suite of services or specialize in specific areas. Communication and collaboration: Choose an agency that values clear communication and collaboration. Ensure they understand your business objectives and are willing to work closely with you to achieve them.",
    },
    {
      question:
        "How long does it take to see results from SEO efforts by an SEO company in Navi Mumbai?",
      answer:
        "The timeline for seeing results from SEO efforts by an SEO company in Navi Mumbai can vary depending on a variety of factors, including the competitiveness of your industry, the current state of your website, and the specific strategies employed by the SEO company. In general, however, it can take several months to start seeing significant improvements in your website's ranking and organic traffic. SEO is a long-term investment, and it often requires ongoing effort and monitoring to maintain and improve your website's performance over time.",
    },
    {
      question:
        "What is influencer marketing and why is it important for businesses in Navi Mumbai?",
      answer:
        "questionquestion marketing is a form of marketing where businesses collaborate with influential individuals on social media platforms to promote their products or services. In Navi Mumbai, influencer marketing holds significant importance due to its ability to reach a targeted audience effectively. By leveraging the credibility and reach of influencers, businesses can enhance brand visibility, credibility, and engagement within the local market.",
    },
    {
      question: "What Role Does Public Relations Play in Business?",
      answer:
        "Public relations plays a crucial role in business by helping to build and maintain a positive reputation, enhancing credibility, and fostering trust among stakeholders. It acts as a bridge between the organization and its target audience, facilitating effective communication and engagement. PR efforts contribute to brand awareness, customer loyalty, and ultimately, business growth. Additionally, in times of crisis or controversy, PR professionals play a vital role in managing reputation damage and mitigating negative publicity.",
    },
  ];
  const [expanded, setExpanded] = useState(null);

  const toggleAccordion = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };
  return (
    <>
      <Imageslider />
      <div className="mb-5">
        <div className="text-left px-5 md:px-20 ">
          <div className="text-center py-16">
            <div className="text-center border-l-4 px-5 border-blue-500">
              <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
                {/* <Typewriter
                  options={{
                    strings: [" FAQ"],
                    autoStart: true,
                    loop: true,
                  }}
                /> */}
                FAQ
              </h1>

              <motion.h1
                className="text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Find What You're Looking For And Get the Answers You Need
              </motion.h1>
            </div>
          </div>
        </div>
        <Accordion
          collapseAll
          className="md:mx-24 mx-7 text-left border-1 text-xl "
        >
          {faqData.map((i, index) => (
            <Accordion.Panel key={index} className="border-1 ">
              <Accordion.Title className="my-5  lg:text-[1.5vw]">
                {i.question}
              </Accordion.Title>
              <Accordion.Content>
                <p className="text-gray-500 lg:text-[1.5vw] dark:text-gray-400"> 
                {i.answer}
                </p>
                </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>


      </div>

      
    </>
  );
};

export default Faq;
