import React from "react";

const WebAnalysis = () => {
  return (
    <div>
      <div className="bg-[#fcb03b]">
        <div className="md:px-20 py-24 imgbg w-full   rounded-xl shadow-md overflow-hidden  pb-10 ">
          <div className="lg:mx-10 md:flex-row lg:flex ">
            <div className="pl-8 pr-8 md:w-full w-full   my-auto">
              <h1 className="text-6xl text-left py-5">Allow Us to Grow Your Company to New Heights!</h1>
              
              
            </div>

            <div className="md:w-full w-[100vw] md:py-0 py-12 my-12 md:my-0 px-5">
              <img
                className="  object-cover md:w-full md:h-full   "
                src="https://html.crumina.net/html-seosight/img/analysis.jpg"
                alt="Modern building architecture"
                style={{
                  boxShadow: "24px 50px 60px rgba(0, 0, 0, 0.3)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebAnalysis;
