import React from 'react'
import Imageslider from '../component/Imageslider'

const Backpage = () => {
  return (
    <>
    <Imageslider />
    </>
  )
}

export default Backpage