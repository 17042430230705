import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";

import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import ServicesPage from "./ServicesPage";
import ContactPage from "./ContactPage";
import Navbar from "./component/Navbar";
import Branding from "./Branding";
import Websitedev from "./Websitedev";
import Media from "./Media";
import Influencer from "./Influencer";
import PR from "./PR";
import SEO from "./SEO";
import Account from "./Account";
// pagination routing
import Backpage from "./sections/Backpage";
import Page1 from "./sections/Page1";
import Page2 from "./sections/Page2";
import Page3 from "./sections/Page3";
import Nextpage from "./sections/Nextpage";

import Footer from "./component/Footer";

import { SplineViewer } from "@splinetool/viewer";
import LocomotiveScroll from "locomotive-scroll";
import BackToTop from "./component/BackToTop";
import Faq from "./sections/Faq";
import Privacypolicy from "./component/Privacypolicy";
import Marquee from "./component/Marquee";
import ENQUIRE from "./component/ENQUIRE";
import Blogs from "./sections/Blogs";

function App() {
  const locomotiveScroll = new LocomotiveScroll();
  return (
    <div className="App">
      <Router>
        <div className="min-h-screen flex flex-col ">
          <div className="spline-viewer-background">
            {/* <spline-viewer className="" url="https://prod.spline.design/PBQQBw8bfXDhBo7w/scene.splinecode"></spline-viewer>  */}
          </div>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/branding" element={<Branding />} />
            <Route path="/website_development" element={<Websitedev />} />
            <Route path="/media" element={<Media />} />
            <Route
              path="/SEO"
              element={<SEO />}
            />
            <Route path="/influencer_marketing" element={<Influencer />} />
            <Route path="/PR" element={<PR />} />
            <Route path="/account" element={<Account />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/Blog" element={<Blogs />} />
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            {/* pagination Rout */}
            <Route path="/back" element={<Backpage />} />
            <Route path="/Page1" element={<Page1 />} />
            <Route path="/Page2" element={<Page2 />} />
            <Route path="/Page3" element={<Page3 />} />
            <Route path="/next" element={<Nextpage />} />
          </Routes>
          <BackToTop />
          <ENQUIRE />
          <Marquee/>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
