import { useState, useEffect, useRef } from "react";
import ContactForm from "./ContactForm";

const Hero = ({ mycloseform }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const formRef = useRef(null);

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible);
  };

  const closeform = () => {
    setIsFormVisible(false);
  };

  const handleScroll = () => {
    if (isFormVisible) {
      window.removeEventListener("scroll", handleScroll);
    }
  };

  useEffect(() => {
    if (isFormVisible) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [isFormVisible]);
  return (
    <>
      <main className=" fixed top-0 left-0 z-[99] w-full h-full bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative   mx-auto">
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <button
              className="absolute top-5 right-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={mycloseform}
            >
              X
            </button>
            {/* <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={toggleForm}
            >
              {isFormVisible ? "Close Form" : "Open Form"}
            </button> */}
            {!isFormVisible && (
              <div
                ref={formRef}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.key === "Escape" && toggleForm()}
              >
                <div className="flex p-10  gap-6">
                  {/* <form className="mt-6 md:w-1/2 w-full">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
                        Name
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
                        Email
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="email"
                        placeholder="Your Email"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
                        Phone No
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="number"
                        placeholder="Your Phone No"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
                        Message
                      </label>
                      <textarea
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        rows="4"
                        placeholder="Your Message"
                      />
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                    
                    </div>
                  </form> */}

                  <ContactForm />
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Hero;
