import React from "react";
import { motion } from "framer-motion";
import Carousel from "./Carousel";
import "../App.css";
import Typewriter from "typewriter-effect";

const Featured = () => {
  return (
    <>
      <div data-scroll="out" data-splitting className=" ">
        <div className="text-left px-5 md:px-20">
          <div className=" pt-16">
            <div className="text-center border-l-4 px-5 border-blue-500">
              <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left 
              
              
              ">
                {/* Featured Blog Posts */}
                {/* <Typewriter
                options={{
                  strings: [" Featured Blog Posts"],
                  autoStart: true,
                  loop: true,
                  
                }}
                
              /> */}
              Featured Blog Posts
              </h1>

              <motion.p
                className="text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                onAnimationComplete={() => {
                  const heading = document.querySelector("h1");
                  heading.classList.add("locomotive-text-enter");
                }}
              >
                Notes, guides and tips from Karn
              </motion.p>
            </div>
          </div>
        </div>

        <div className="cards pt-20">
          <Carousel />
         
        </div>
      </div>
    </>
  );
};

export default Featured;
