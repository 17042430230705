import React from "react";
import { Link } from "react-router-dom";
import Marquee from "./Marquee";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
function Footer() {
  const emailAddress = "karnmarketingwarfarellp@gmail.com";
  const mailtoLink = `mailto:${emailAddress}`;
  const phoneNumber = "7304675307";
  const telLink = `tel:${phoneNumber}`;

  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
    },
    logo: {
      width: "100px",
      height: "100px",
      margin: "10px",
    },
    nav: {
      display: "flex",
      flexWrap: "wrap",
      width: "30%",
    },
    navItem: {
      width: "100%",
      // margin: "10px 0",
    },
    contact: {
      display: "flex",
      flexWrap: "wrap",
      width: "30%",
    },
    contactItem: {
      width: "100%",
      margin: "10px 0",
    },
  };
  const slides = [
    {
      image:
        "https://karnllp.files.wordpress.com/2023/10/cropped-karn-logo.png",
      alt: "image 1",
      text: "From Leads To Loyalty From Leads To Loyalty",
      btntext: "Learn more",
      btnurl: "/services",
    },
    {
      image:
        "https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png",
      alt: "image 2",
      text: "From Leads To Loyalty From Leads To Loyalty From Leads To Loyalty",
      btntext: "Learn more",
      btnurl: "/services",
    },
    {
      image:
        "https://www.edigitalagency.com.au/wp-content/uploads/Facebook-logo-blue-circle-large-transparent-png.png",
      alt: "image 3",
      text: "From Leads To Loyalty",
      btntext: "Learn more",
      btnurl: "/services",
    },
    {
      image:
        "https://seeklogo.com/images/T/twitter-x-logo-19D2657BAA-seeklogo.com.png?v=638258862870000000",
      alt: "image 1",
      text: "From Leads To Loyalty From Leads To Loyalty",
      btntext: "Learn more",
      btnurl: "/services",
    },
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/BMW.svg/600px-BMW.svg.png",
      alt: "image 2",
      text: "From Leads To Loyalty From Leads To Loyalty From Leads To Loyalty",
      btntext: "Learn more",
      btnurl: "/services",
    },
    {
      image:
        "https://freelogopng.com/images/all_img/1686496680audi-logo-transparent.png",
      alt: "image 3",
      text: "From Leads To Loyalty",
      btntext: "Learn more",
      btnurl: "/services",
    },
  ];
  return (
    <>
      {/* <Marquee/> */}
      <footer className=" bg-[#2c2c2c] text-white  ">
        <div className="  ">
          <footer className="text-white">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
              <div className="md:flex md:justify-between w-full">
                <div className="mb-6 md:mb-0  w-3/4">
                  <a href="/">
                    <img
                      className="h-1/2 w-auto "
                      src="/logos/whitelogo.png"
                      alt="Workflow"
                    />
                  </a>
                  <div className="flex mt-4 justify-center md:w-1/2   ">
                    <a
                      href="https://www.facebook.com/karnmarketing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-100 dark:hover:text-white ms-5"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      href="https://www.instagram.com/karnmarketing/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-100 dark:hover:text-white ms-5"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href="https://www.linkedin.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-100 dark:hover:text-white ms-5"
                    >
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>

                <div className="grid grid-cols-2  sm:grid-cols-3 ">
                  <div>
                    <h2 className="mb-6 text-sm font-semibold text-gray-100 uppercase dark:text-white text-left">
                      QUICK LINKS
                    </h2>

                    {/*  */}

                    <nav className="text-gray-500 dark:text-gray-400 font-medium text-left">
                      <a
                        href="/"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>Home</p>
                      </a>
                      <a
                        href="/about"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>About</p>
                      </a>
                      <a
                        href="/Blog"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>Blog</p>
                      </a>
                      <a
                        href="/faq"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>FAQ</p>
                      </a>
                      <a
                        href="/privacypolicy"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>Privacy Policy</p>
                      </a>
                      <a
                        href="/contact"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>Contact</p>
                      </a>
                    </nav>
                    {/*  */}
                  </div>
                  <div>
                    <h2 className="text-left mb-6 text-sm font-semibold text-gray-100 uppercase dark:text-white">
                      SERVICES
                    </h2>
                    {/*  */}
                    <nav className="text-left text-gray-500 dark:text-gray-400 font-medium">
                      <a
                        href="/Branding"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p> Branding</p>
                      </a>
                      <a
                        href="/website_development"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p> Website Development</p>
                      </a>
                      <a
                        href="/media"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>Social Media Marketing</p>
                      </a>
                      <a
                        href="/SEO"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>SEO</p>
                      </a>

                      <a
                        href="/influencer_marketing"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>Influencer Marketing</p>
                      </a>
                      <a
                        href="/PR"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p> PR Managment</p>
                      </a>
                    </nav>
                    {/*  */}
                  </div>
                  <div>
                    <h2 className="text-left mb-6 text-sm font-semibold text-gray-100 uppercase dark:text-white">
                      CONTACT
                    </h2>
                    <nav className="text-left text-gray-500 dark:text-gray-400 font-medium">
                      <a
                        href={`mailto:${emailAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block text-gray-400 hover:text-white mb-2  "
                      >
                        <p>{emailAddress}</p>
                      </a>
                      <li style={{ listStyle: "none" }}>
                        <a
                          href={telLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-gray-400 hover:text-white mb-2"
                        >
                          <p>{phoneNumber}</p>
                        </a>
                      </li>
                      <a
                        href="https://www.google.com/maps?ll=19.114862,73.005521&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=8289053274308515687"
                        target="_blank"
                        style={styles.navItem}
                        className="block text-gray-400 hover:text-white mb-2"
                      >
                        <p>
                          {" "}
                          Ambe Prerna Cooperative Housing Society, Santoshi Mata
                          Mandir Marg, Savali, Ghansoli, Navi Mumbai,
                          Maharashtra 400701
                        </p>
                      </a>
                    </nav>
                    {/*  */}

                    {/*  */}
                  </div>
                </div>
              </div>
              <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
              <div className="items-center justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                  Copyright © {new Date().getFullYear()} Karn. All rights
                  reserved.
                </span>
              </div>
            </div>
          </footer>

          {/*  */}

          {/*  */}

          {/*  */}
        </div>
      </footer>
    </>
  );
}

export default Footer;

{
  /* <h1 className="sm:block md:hidden text-2xl text-black font-semibold">
            Our Partners
          </h1> */
}
{
  /* <div className="flex flex-wrap gap-5 justify-center py-2">
            {slides.map((e) => (
              <img
                key={e.alt}
                src={e.image}
                alt={e.alt}
                className="w-20 h-20 object-contain"
              />
            ))}
          </div> */
}

{
  /* <h2 className="text-2xl font-bold mb-4 text-left md:px-0 px-5">
            Karn
          </h2> */
}

{
  /* <nav className="w-full md:w-1/3 text-left md:px-0 px-5 pt-5 md:text-left">
            <h3 className="text-2xl font-bold mb-4">Follow Us</h3>
            <ul className="list-reset">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61555388566070&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-400 hover:text-white mb-2"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-400 hover:text-white mb-2"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-400 hover:text-white mb-2"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/karnmarketing/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-400 hover:text-white"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </nav> */
}
