import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

// const settings2 = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   autoplay: true,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 640,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

 const settings2 = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1890,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

const backgroundImageStyle = {
  backgroundImage: `url(https://img.freepik.com/free-photo/toy-bricks-table-with-word-blog_144627-47465.jpg?t=st=1710339674~exp=1710343274~hmac=8db178857f74d0a0377baa74b30c05b93174bb4f037693b85dc6d26b1bacf2a8&w=1380)`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  padding: '2rem',
  
};

const Carousel = () => {
  const testimonials = [
    {
      id: 1,
      name: "Growth Marketing",
      quote: "10 Things to keep in mind before setting an ABM framework",
      image: "https://picsum.photos/id/1015/100/100",
    },
    {
      id: 2,
      name: "Pharma Marketing",
      quote: "The New-age Platform for Rx Marketing",
      image: "https://picsum.photos/id/1016/100/100",
    },
    {
      id: 3,
      name: "Pharma Marketing",
      quote: "10 Pharmaceutical Marketing Strategies for Physicians",
      image: "https://picsum.photos/id/1016/100/100",
    },
    {
      id: 4,
      name: "Pharma Marketing",
      quote: "Step by Step Guide to Make a Successful Pharma Marketing Plan",
      image: "https://picsum.photos/id/1018/100/100",
    },
  ];
  

  return (
    <div className="mainbox md:py-24 w-full "
    style={backgroundImageStyle}>

      <Slider {...settings2} className="slider  md:mx-7 ">
        {testimonials.map((e, i) => {
          return (
            <div className="w-full ">
              <div className="md:ml-10 md:mr-10 " key={i}>
                <div className="card ">
                  <div className="header ">
                    <div className="image">
                      <img
                        src={e.image}
                        className="w-full h-full rounded-full"
                      />
                    </div>
                  </div>
                  <p className="name">{e.name}</p>
                  <p className="message">{e.quote}</p>
                  <button>
                    <Link to={`/services/#${e.id}`}>Read More</Link>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
