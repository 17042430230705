import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import { Link } from "react-router-dom";
import Modal from "react-modal";

const Offer = ({
  title,
  description,
  image,
  path,
  heading,
  service,
  data1,
  data2,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const textRef = useRef(null);
  useEffect(() => {
    gsap.from(textRef.current, {
      opacity: 1,
      y: 20,
      duration: 0.9,
      delay: 0.3,
      ease: "power3.out",
    });
  }, []);
  return (
    <>
      <div className="container">
        <motion.div
          className="p-6 w-96 sm:w-80 mx-auto group"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex flex-col">
            <img
              src={image}
              alt={title}
              className="object-cover mx-auto mb-4 rounded-t-lg"
            />
            <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
              {title}
            </h2>
            <p className="text-[#0079c6]">{description}</p>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Offer;
