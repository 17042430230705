import React from "react";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
const Whyus = () => {
  return (
    <>
      <div className="text-left px-5 md:px-20">
        <div className="text-center ">
          <div className="text-center border-l-4 px-5 border-blue-500">
            <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
              {/* <Typewriter
                options={{
                  strings: [" Why Us"],
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              Why Us
            </h1>

            <motion.h1
              className="capitalize text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Empower Your Journey: Make the right choice for your success - choose us today

            </motion.h1>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap md:mx-24 px-4 mt-16">
      <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 ">
          <img
            className="h-full max-w-full lg:w-3/4 rounded-lg"
            src="https://img.freepik.com/free-photo/way-success-speech-bubble-chart_53876-123889.jpg?t=st=1711021294~exp=1711024894~hmac=5c90139eab1e758a41e9089543348fd507ea82986dfc1056d997e37260e8c79b&w=740"
            alt="image description"
          />
        </div>
        <div className="w-full  md:w-1/2  mb-4  text-left md:p-5 ">
          
          <div className="flex gap-5 ">
            
            <div>
              <p className="lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                <b>Sharp Strategic Insights:</b> We delve deep into your brand
                identity, target audience, and industry trends to develop a
                personalized digital marketing roadmap that aligns with your
                specific goals.
              </p>
            </div>
          </div>

          <div className="flex gap-5 pt-5 lg:pt-8">
            
            <div>
              
              <p className="lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                <b>Data-Driven Decisions:</b> We don't believe in guesswork. We
                leverage cutting-edge analytics tools to measure performance,
                optimize strategies, and ensure your marketing budget delivers
                the maximum ROI. Our team of seasoned marketing warriors is
                brimming with creative flair. We craft compelling content,
                captivating visuals, and engaging campaigns that resonate with
                your audience. 
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Whyus;
