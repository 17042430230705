import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import { Link } from "react-router-dom";
import Modal from "react-modal";

const Item = ({ item }) => {
  return <p style={{ whiteSpace: "normal" }}>{item}</p>;
};

const Mediacard = ({
  title,
  description,
  image,
  path,
  heading,
  service,
  data1,
  data2,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const textRef = useRef(null);
  useEffect(() => {
    gsap.from(textRef.current, {
      opacity: 1,
      y: 20,
      duration: 0.9,
      delay: 0.3,
      ease: "power3.out",
    });
  }, []);
  return (
    <>
      <div className="container ">
        <motion.div
          className="p-6 w-96  mx-auto group"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex flex-col">
            <img
              src={image}
              alt={title}
              className="object-cover mx-auto mb-4 rounded-t-lg"
            />
            <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
              {title}
            </h2>
           
            <button
              className="mt-7 p-3 inline-flex items-center justify-center mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg bg-white dark:bg-gray-900 opacity-0 group-hover:opacity-100 transition-opacity ease-in duration-75"
              onClick={openModal}
            >
              <span className=" ">
                <Link
                  //   to={path}
                  className="uppercase  hover:bg-[#eb754d] hover:text-white p-4 mt-2 rounded-xl font-bold text-orange-400 border border-orange-400"
                >
                  Know More
                </Link>
              </span>
            </button>

            
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Modal"
              className="bg-white w-[80vw] h-[80vh]  md:w-full  rounded-lg shadow-lg p-8 relative lg:mx-24 mx-10"
              overlayClassName="fixed  top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            >
              <img
                src={image}
                alt={title}
                className="object-cover mx-auto mb-4 rounded-t-lg"
              />
              <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                {heading}
              </h2>
              <p className="text-[#0079c6] text-sm  md:text-[1.2vw] text-center">{description}</p>
              <div className="md:my-5">
              <hr className="md:mx-5 hidden md:block"/>
              </div>
              <h2 className="md:text-3xl text-lg text-center mt-2 md:mt-5">{service}</h2>
              <div className="flex  mx-auto lg:w-1/2 ">
                <div className="w-1/2 md:p-4 ">
                  {data1 &&
                    data1.map((item, index) => (
                        <div className="flex gap-3 text-sm md:p-5 p-2 md:text-[1.2vw]">
                       ✓
                        <Item key={index} item={item} />
                      </div>
                    ))}
                </div>
                <div className="w-1/2 md:p-4">
                  {data2 &&
                    data2.map((item, index) => (
                      <div className="flex gap-3 text-sm md:p-5 p-2 md:text-[1.2vw]">
                        ✓
                        <Item key={index} item={item} />
                      </div>
                    ))}
                </div>
              </div>
              <button
                className="mt-7 p-3 inline-flex items-center justify-center mb-2 me-2 overflow-hidden text-sm font-medium  rounded-lg bg-white  opacity-0 group-hover:opacity-100 transition-opacity ease-in duration-75"
                onClick={closeModal}
              >
                <span className="">
                  <Link className="uppercase hover:bg-[#eb754d] hover:text-white p-4 mt-2 rounded-xl font-bold text-orange-400 border border-orange-400">
                    Close
                  </Link>
                </span>
              </button>
            </Modal>
{/* 
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Modal"
              className="bg-white w-full dark:bg-gray-900 rounded-lg shadow-lg p-8 relative lg:mx-24 mx-10"
              overlayClassName="fixed  top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            >
              <img
                src={image}
                alt={title}
                className="object-cover mx-auto mb-4 rounded-t-lg"
              />
              <h2 className="text-xl font-bold mb-2 text-[#ffac32] text-center">
                {heading}
              </h2>
              <p className="text-[#0079c6] text-xl  md:text-[1.2vw] text-center">{description}</p>
              <div className="my-5">
              <hr className="mx-5"/>
              </div>
              <h2 className="text-3xl text-center mt-5">{service}</h2>
              <div className="flex  mx-auto lg:w-1/2 ">
                <div className="w-1/2 p-4 ">
                  {data1 &&
                    data1.map((item, index) => (
                        <div className="flex gap-3 text-xl p-5 md:text-[1.2vw]">
                       ✓
                        <Item key={index} item={item} />
                      </div>
                    ))}
                </div>
                <div className="w-1/2 p-4">
                  {data2 &&
                    data2.map((item, index) => (
                      <div className="flex gap-3 text-xl p-5 md:text-[1.2vw]">
                        ✓
                        <Item key={index} item={item} />
                      </div>
                    ))}
                </div>
              </div>
              <button
                className="mt-7 p-3 inline-flex items-center justify-center mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg bg-white dark:bg-gray-900 opacity-0 group-hover:opacity-100 transition-opacity ease-in duration-75"
                onClick={closeModal}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md">
                  <Link className="uppercase hover:bg-[#eb754d] hover:text-white p-4 mt-2 rounded-xl font-bold text-orange-400 border border-orange-400">
                    Close
                  </Link>
                </span>
              </button>
            </Modal> */}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Mediacard;
