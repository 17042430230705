import React from "react";
import Imageslider from "../component/Imageslider";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import { AiOutlineUser } from "react-icons/ai";
import { BiSolidComment } from "react-icons/bi";
import { AiFillTag } from "react-icons/ai";
const Blogs = () => {
  return (
    <>
      <Imageslider />
      <div className="mt-14">
        {/*  */}
        <div className="flex w-full lg:flex-row flex-col gap-24  justify-between xl:px-28 px-14">
          <div className="imgs w-full  ">
            <div>
              <div className="">
                <img
                  src="https://htmldemo.net/raymond/raymond/img/blog/1.webp"
                  alt=""
                />
                <div className="flex w-full  justify-evenly items-center py-5">
                  <span className="flex items-center gap-2">
                    {" "}
                    <AiOutlineUser /> By Johny Lee
                  </span>
                  <span className="flex items-center gap-2">
                    {" "}
                    <BiSolidComment />0 Comment
                  </span>
                  <span className="flex items-center gap-2">
                    {" "}
                    <AiFillTag />
                    Fashion, jacket, men
                  </span>
                </div>
              </div>
              <hr />
              <div className="text-left my-2">
                <p className="font-bold">Summer Classics In Positano</p>
                <p>
                  Donec pellentesque, lacus in venenatis suscipit, mi arcu
                  consectetur leo, vulputate congue dolor eros ut sapien. Mauris
                  erat quam, amet convallis ac, vulputate ultricies orci.
                  Vestibulum porta iaculis felis, eget tristique ante egestas
                  quis. Sed non quis, convallis felis. Etiam id elit id elit
                  dapibus blandit. Phasellus nec erat
                </p>
                <button className="border-2 p-2 rounded-md my-5">
                  Read More
                </button>
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src="https://htmldemo.net/raymond/raymond/img/blog/2.webp"
                  alt=""
                />
                <div className="flex w-full  justify-evenly items-center py-5">
                  <span className="flex items-center gap-2">
                    {" "}
                    <AiOutlineUser /> By Johny Lee
                  </span>
                  <span className="flex items-center gap-2">
                    {" "}
                    <BiSolidComment />0 Comment
                  </span>
                  <span className="flex items-center gap-2">
                    {" "}
                    <AiFillTag />
                    Fashion, jacket, men
                  </span>
                </div>
              </div>
              <hr />
              <div className="text-left my-2">
                <p className="font-bold">Summer Classics In Positano</p>
                <p>
                  Donec pellentesque, lacus in venenatis suscipit, mi arcu
                  consectetur leo, vulputate congue dolor eros ut sapien. Mauris
                  erat quam, amet convallis ac, vulputate ultricies orci.
                  Vestibulum porta iaculis felis, eget tristique ante egestas
                  quis. Sed non quis, convallis felis. Etiam id elit id elit
                  dapibus blandit. Phasellus nec erat
                </p>
                <button className="border-2 p-2 rounded-md my-5">
                  Read More
                </button>
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src="https://htmldemo.net/raymond/raymond/img/blog/3.webp"
                  alt=""
                />
                <div className="flex w-full  justify-evenly items-center py-5">
                  <span className="flex items-center gap-2">
                    {" "}
                    <AiOutlineUser /> By Johny Lee
                  </span>
                  <span className="flex items-center gap-2">
                    {" "}
                    <BiSolidComment />0 Comment
                  </span>
                  <span className="flex items-center gap-2">
                    {" "}
                    <AiFillTag />
                    Fashion, jacket, men
                  </span>
                </div>
              </div>
              <hr />
              <div className="text-left my-2">
                <p className="font-bold">Summer Classics In Positano</p>
                <p>
                  Donec pellentesque, lacus in venenatis suscipit, mi arcu
                  consectetur leo, vulputate congue dolor eros ut sapien. Mauris
                  erat quam, amet convallis ac, vulputate ultricies orci.
                  Vestibulum porta iaculis felis, eget tristique ante egestas
                  quis. Sed non quis, convallis felis. Etiam id elit id elit
                  dapibus blandit. Phasellus nec erat
                </p>
                <button className="border-2 p-2 rounded-md my-5">
                  Read More
                </button>
              </div>
            </div>
          </div>

          <div className="w-1/2 text-left   ">
            <p>POPULAR POSTS</p>

            <div className=" ">
              <div>
                <div className="flex my-10 gap-10 ">
                  <div className="  bg-red-500 ">
                    <img
                      src="https://htmldemo.net/raymond/raymond/img/blog/blog-left-sidebar/s1.webp"
                      alt=""
                      className=" mx-auto"
                    />
                  </div>
                  <div className="w-full  my-auto">
                    <p>Photography Tips From Wai Su</p>
                    <p>Jan, 20, 2016</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className="flex my-10 gap-10">
                  <div className="  ">
                    <img
                      src="https://htmldemo.net/raymond/raymond/img/blog/blog-left-sidebar/s1.webp"
                      alt=""
                      className=" mx-auto"
                    />
                  </div>
                  <div className="w-full  my-auto">
                    <p>Photography Tips From Wai Su</p>
                    <p>Jan, 20, 2016</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className="flex my-10 gap-10">
                  <div className="  ">
                    <img
                      src="https://htmldemo.net/raymond/raymond/img/blog/blog-left-sidebar/s1.webp"
                      alt=""
                      className=" mx-auto"
                    />
                  </div>
                  <div className="w-full  my-auto">
                    <p>Photography Tips From Wai Su</p>
                    <p>Jan, 20, 2016</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className="flex my-10 gap-10">
                  <div className="  ">
                    <img
                      src="https://htmldemo.net/raymond/raymond/img/blog/blog-left-sidebar/s1.webp"
                      alt=""
                      className=" mx-auto"
                    />
                  </div>
                  <div className="w-full  my-auto">
                    <p>Photography Tips From Wai Su</p>
                    <p>Jan, 20, 2016</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className="flex my-10 gap-10">
                  <div className=" ">
                    <img
                      src="https://htmldemo.net/raymond/raymond/img/blog/blog-left-sidebar/s1.webp"
                      alt=""
                      className=" mx-auto"
                    />
                  </div>
                  <div className="w-full  my-auto">
                    <p>Photography Tips From Wai Su</p>
                    <p>Jan, 20, 2016</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
            </div>
{/*  */}
            <div className=" text-left">

            
            <p className="my-10 flex justify-center ">RECENT POSTS</p>
            <div>
              <div>
                <div className=" mt-3">
                  <div className="w-full  my-auto">
                    <p className="pb-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr />
              </div>
              <div className="">
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr />
              </div>
              <div>
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr />
              </div>
              <div>
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
            </div>

            {/*  */}

            <p className="my-10 flex justify-center">RECENT COMMENT</p>
            <div>
              <div>
                <div className=" mt-3">
                  <div className="w-full  my-auto">
                    <p className="pb-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div className="">
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
            </div>
            {/*  */}

            <p className="my-10 flex justify-center">ARCHIVES</p>
            <div>
              <div>
                <div className=" mt-3">
                  <div className="w-full  my-auto">
                    <p className="pb-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div className="">
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
            </div>

            {/*  */}

            <p className="my-10 flex justify-center">CATEGORIES</p>
            <div>
              <div>
                <div className=" mt-3">
                  <div className="w-full  my-auto">
                    <p className="pb-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div className="">
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
            </div>

            <p className="my-10 flex justify-center">META</p>
            <div>
              <div>
                <div className=" mt-3">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div className="">
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div>
                <div className=" mt-3">
                  <div className="w-full  my-auto">
                    <p className="pb-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
              <div className="">
                <div className=" ">
                  <div className="w-full  my-auto">
                    <p className="py-5">Curabitur porttitor varius leo</p>
                  </div>
                </div>
                <hr className="mx-auto" />
              </div>
            </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      <Pagination />
    </>
  );
};

export default Blogs;
