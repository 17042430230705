import React from "react";

const SeoServices = () => {
  return (
    <div>
      <div className="mt-20 py-10 bg-[#f7f9f9] md:px-24 px-5">
        <h1 className="text-5xl ">
          Our Search Engine Optimization Core Services Include:
        </h1>
        <div className="flex flex-col md:flex-row mt-32 gap-5 text-left ">
          <div className="w-full relative">
            <div className="absolute -top-14  w-full flex justify-center">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box15.png"
                alt=""
              />
            </div>
            <div className="bg-white h-[50vh]  py-14 px-5 ">
              <p className="text-center text-2xl py-2">TECHNICAL SEO</p>
              <p>
                It,'s a process of ensuring that a website meets the technical
                requirements of modern search engines with the goal of improved
                organic rankings.
              </p>
            </div>
          </div>
          <div className="w-full relative">
            <div className="absolute -top-14  w-full flex justify-center">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box16.png"
                alt=""
              />
            </div>
            <div className="bg-white h-[50vh] py-14 px-5 ">
              <p className="text-center text-2xl py-2">ON PAGE SEO</p>
              <p>
                It is the process of optimizing webpages and their content for
                both search engines and users.
              </p>
            </div>
          </div>
          <div className="w-full relative">
            <div className="absolute -top-14  w-full flex justify-center">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box17.png"
                alt=""
              />
            </div>
            <div className="bg-white h-[50vh] py-14 px-5 ">
              <p className="text-center text-2xl py-2">LINK BUILDING</p>
              <p>
                It is activities done off of a website to increase the site's
                search engine rankings
              </p>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeoServices;
