import React from "react";
import Imageslider from "./component/Imageslider";
import { motion, useDragControls } from "framer-motion";
import Typewriter from "typewriter-effect";
import SeoServices from "./component/SeoServices";
import WebAnalysis from "./component/WebAnalysis";
const SEO = () => {
  return (
    <>
      <Imageslider />

      <div className="mt-20  mx-auto lg:w-4/5">
        <div className="flex gap-5 md:flex-row flex-col  md:mx-14 mx-5 justify-between">
          <div className="w-full md:w-1/2  flex justify-evenly ">
            <img
              src="https://html.crumina.net/html-seosight/img/mac-book.png"
              alt=""
            />
          </div>
          <div className="w-full md:w-1/2  text-left justify-evenly mt-10 md:mt-0">
            <p className="text-[2.5vw]  py-2 capitalize">
              Best SEO Agency In Navi Mumbai to enhance the sale
            </p>
            <p className="lg:text-[1.2vw] text-gray-500">
              KARN Marketing is the first-rate SEO Company In Navi Mumbai for
              business across the city. With our SEO services like on-page
              optimization, Technical optimization, off-page optimization, local
              SEO, and content optimization KARN will assist businesses to
              reinforce to find their target audience and help to boost
              business.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#fb923c] mt-20 py-20">
        <h1 className="text-5xl my-10 capitalize text-left mx-5 md:mx-24">
          Our primary motto behind search engine optimization is
        </h1>
        <div className="flex md:flex-row flex-col gap-24 md:gap-5 mx-5 md:mx-24  text-left">
          <div className=" w-full md:w-1/2 border-2 flex justify-center items-center">
            <p className=" text-[#f1f1fa] text-2xl py-2  z-10">Relevancy</p>
          </div>
          <div className=" w-full md:w-1/2 border-2 flex justify-center items-center">
            {" "}
            <p className=" text-[#f1f1fa] text-2xl py-2 z-20">Authority</p>
          </div>
          <div className=" w-full md:w-1/2 border-2 flex justify-center items-center">
            {" "}
            <p className=" text-[#f1f1fa] text-2xl py-2 z-20">User Experience</p>
          </div>
          <div className=" w-full md:w-1/2 border-2 flex justify-center items-center">
            <p className=" text-[#f1f1fa] text-2xl py-2 z-20">Constantly Evolving</p>
          </div>
        </div>
      </div>

      {/*  */}

      {/*  */}
      <div className="md:mx-auto lg:w-[80vw] md:w-2/3 w-full px-5 mt-20 text-left">
        <p className="text-5xl ">Why Is SEO Important For Your Business?</p>
        <div className="flex lg:flex-row flex-col text-left mt-8 gap-5">
          <div className="w-full lg:w-1/2 text-2xl lg:text-[1.2vw] flex gap-5 text-[#acacbf]">
            <p className="text-red-600"> &#10003;</p>{" "}
            <p>
              {" "}
              <span className="text-black">
                <b>User Experience</b>
              </span>
              <br />
              The user reveals in is how a user interacts with and stories a
              product, gadget, or service.
            </p>
          </div>
          <div className="w-full lg:w-1/2 text-2xl lg:text-[1.2vw] flex gap-5 text-[#acacbf]">
            <p className="text-red-600"> &#10003;</p>{" "}
            <p>
              <span className="text-black">
                <b>Visibility</b>
              </span>
              <br />
              search engine optimization offers your emblem the visibility it
              desires to attract clients
            </p>
          </div>
          <div className="w-full lg:w-1/2 text-2xl lg:text-[1.2vw] flex gap-5 text-[#acacbf]">
            <p className="text-red-600"> &#10003;</p>{" "}
            <p>
              <span className="text-black">
                <b>Traffic</b>
              </span>
              <br />
              Being visible and in an amazing position on SERP ought to result
              in increased internet site visitors.g and marketing efforts.
            </p>
          </div>
          <div className="w-full lg:w-1/2 text-2xl lg:text-[1.2vw] flex gap-5 text-[#acacbf]">
            <p className="text-red-600"> &#10003;</p>{" "}
            <p>
              <span className="text-black">
                <b> Trustworthiness</b>
              </span>
              <br />
              Companies with sturdy search engine optimization and content
              material strategies also tend to be more relied on.
            </p>
          </div>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <SeoServices />
      <WebAnalysis />
      {/*  */}

      {/*  Page Content */}
      <div className="mt-20  mx-auto">
        <h1 className="text-5xl mb-10">
          WHAT OUR PROFESSIONAL SEO SERVICES INCLUDE?
        </h1>

        <div className="flex lg:flex-row flex-col  md:mx-14 mx-5 justify-between gap-32">
          <div className="w-full md:w-1/2  flex justify-evenly ">
            {/* <div className="w-1/2">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box1.png"
                alt=""
              />
            </div> */}
            <div className="text-left pl-5">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box1.png"
                alt=""
              />
              <h1 className="text-xl  text-black">KEYWORDS RESEARCH</h1>
              <p className="text-gray-500 font-thin">
                Keyword research is one of the most crucial additives of any hit
                search engine marketing Campaign. Finding and Targeting the
                right set of Keywords way accelerated possibilities to drive
                applicable organic site site visitors.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex justify-evenly ">
            {/* <div className="w-1/2">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box2.png"
                alt=""
              />
            </div> */}
            <div className="text-left pl-5">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box2.png"
                alt=""
              />
              <h1 className="text-xl  text-black">ON PAGE OPTIMIZATION</h1>
              <p className="text-gray-500 font-thin">
                Onpage A vital principle while coping with on-internet web page
                optimization techniques is to optimize and keep your customers
                in mind not only Search Engines.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex justify-evenly ">
            {/* <div className="w-1/2">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box3.png"
                alt=""
              />
            </div> */}
            <div className="text-left pl-5">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box3.png"
                alt=""
              />
              <h1 className="text-xl  text-black">WEBSITE ANALYSIS</h1>
              <p className="text-gray-500 font-thin">
                Website positive Analysis starts with a Website review, found
                through Content Analysis, Technical assessment, and on-page
                analysis through the use of our search engine marketing Experts
                with the help of top-rated gear like SEMRush, ESLRanksPro, and
                Ahrefs.
              </p>
            </div>
          </div>
        </div>

        <div className="my-32  flex lg:flex-row flex-col  md:mx-14 mx-5 justify-between gap-32">
          <div className="w-full md:w-1/2  flex justify-evenly ">
            {/* <div className="w-1/2">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box4.png"
                alt=""
              />
            </div> */}
            <div className="text-left pl-5">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box4.png"
                alt=""
              />
              <h1 className="text-xl  text-black">CONTENT OPTIMIZATION</h1>
              <p className="text-gray-500 font-thin">
                It consists of writing and optimizing the content material with
                useful and relevant facts for your website that may help clients
                discover the correct facts they are searching out.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex justify-evenly ">
            {/* <div className="w-1/2">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box5.png"
                alt=""
              />
            </div> */}
            <div className="text-left pl-5">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box5.png"
                alt=""
              />
              <h1 className="text-xl  text-black">COMPETITORS ANALYSIS</h1>
              <p className="text-gray-500 font-thin">
                We as an SEO Agency recognize the importance of competitor
                analysis in advance before beginning any search engine
                advertising Campaign. A thorough competitor analysis permits us
                to provide you with first-class SEO techniques that outrank all
                of your opposition in your location of interest.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex justify-evenly ">
            {/* <div className="w-1/2">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box6.png"
                alt=""
              />
            </div> */}
            <div className="text-left pl-5">
              <img
                src="https://html.crumina.net/html-seosight/img/info-box6.png"
                alt=""
              />
              <h1 className="text-xl  text-black">HIGH-QUALITY BACKLINKS</h1>
              <p className="text-gray-500 font-thin">
                Creation Backlink is the fave additive of the Search Engines’
                algorithms and plays an important function in website rankings.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
    </>
  );
};

export default SEO;
