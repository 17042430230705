import React, { useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import Imageslider from "./component/Imageslider";
import { motion, useDragControls } from "framer-motion";
import { Accordion } from "flowbite-react";

import Typewriter from "typewriter-effect";

const Branding = () => {
  const fadeAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });
  //

  const [isKnowledgeShown, setIsKnowledgeShown] = useState(false);
  const [isKnowledgeShown2, setIsKnowledgeShown2] = useState(false);
  const [isKnowledgeShown3, setIsKnowledgeShown3] = useState(false);
  const [isKnowledgeShown4, setIsKnowledgeShown4] = useState(false);

  //

  const [isKnowledgeShown5, setIsKnowledgeShown5] = useState(false);
  const [isKnowledgeShown6, setIsKnowledgeShown6] = useState(false);
  const [isKnowledgeShown7, setIsKnowledgeShown7] = useState(false);
  const [isKnowledgeShown8, setIsKnowledgeShown8] = useState(false);

  //
  const [isKnowledgeShown9, setIsKnowledgeShown9] = useState(false);
  const [isKnowledgeShown10, setIsKnowledgeShown10] = useState(false);
  const [isKnowledgeShown11, setIsKnowledgeShown11] = useState(false);
  const [isKnowledgeShown12, setIsKnowledgeShown12] = useState(false);
  const [isKnowledgeShown13, setIsKnowledgeShown13] = useState(false);
  const [isKnowledgeShown14, setIsKnowledgeShown14] = useState(false);
  const [isKnowledgeShown15, setIsKnowledgeShown15] = useState(false);

  //

  const KARN = [
    {
      name: "Knowledge",
      desc: "We keep up with the latest developments in virtual marketing by always learning about and understanding new trends. about and understanding new trends.",
    },
    {
      name: "Analytics",
      desc: "We don't rely on educated guesses. We use record-keeping information to identify your target market and customize strategies for optimal impact.",
    },
    {
      name: "Outcomes",
      desc: "We prioritize measurable outcomes. We design data-driven programs that provide observable outcomes in line with your company's goals.",
    },
    {
      name: "Storytelling",
      desc: 'We think that stories have great power. We create engrossing stories that captivate your target audience and have an enduring effect. Marketing Warfare: A Strategic Approach We no longer see "advertising and marketing conflict" as chaotic warfare, but rather as a calculated chess match. We take our time to understand your unique company needs, target market, and competitive landscape. This enables us to create specially crafted plans that reach the right people at the right moment with the right message.',
    },
  ];

  const glimpse = [
    {
      name: "Logo Design",
      desc: "We create a lifelong identity for your brand. The first stage is to design an original and meaningful logo that best visually communicates the brand's goals. Our designs are derived from the client's completion of the Logo Brief questionnaire. This makes it easier to understand your brand and create accurate designs. Any brand identity starts with well-established brand guidelines. Excellent logo design is the result of clearly established brand rules. We create a brand catchphrase, brand design components, and a proposition in addition to the logo to help consumers remember the brand.",
    },
    {
      name: "Brand style guide",
      desc: "Document all branding elements, including logo usage, color palette, typography guidelines, and brand messaging. This guide serves as a reference for internal teams and external partners to maintain brand consistency. Clearly outline guidelines for the use of brand assets and intellectual property to protect your brand identity and maintain brand integrity.",
    },
    {
      name: "Packaging design",
      desc: "Your packaging format serves as a visible example of your emblem. Whether it is via coloration schemes, typography, or imagery, make certain that your packaging aligns seamlessly together along with your brand identification. Consistency for the duration of all touchpoints, collectively with packaging, internet website, and social media, reinforces emblem reputation and fosters don't forget amongst your target audience. Packaging allows narrating your emblem story and hooking up with clients on a deeper diploma. Incorporate elements that deliver your emblem's values, venture, and specific promoting proposition. Whether it is via inexperienced substances, personalized messages, or storytelling visuals, infuse your packaging with authenticity to create memorable experiences for your clients.",
    },
    {
      name: "Stationary ",
      desc: "Small yet powerful pillars of branding content that connect with the target market in many ways make up a brand. Another branding collateral is designed to leave a lasting impact on the target audience while remaining true to your company identity. We create brochures, flyers, business cards, envelopes, rate charts, and more as a full-service branding company. Additionally, banners for events, 3D display stalls for events, packaging for product manufacturers, and more. Making customers recall and remember your company for future reference when they need it is the goal.",
    },
    {
      name: "Brand Strategy and Development",
      desc: "Helping businesses define their unique selling proposition (USP) and develop clear, concise messaging that resonates with their target audience.Crafting a consistent personality for the brand's communication style, whether it's playful, authoritative, or something else entirely.Researching the competition to understand their strengths and weaknesses and identify opportunities for differentiation.",
    },
  ];

  const Digital = [
    {
      name: "Pay-Per-Click (PPC) Advertising",
      desc: "We create targeted PPC campaigns that strategically deliver your message to the right audience, maximizing your return on investment (ROI).",
    },
    {
      name: "Social Media Marketing",
      desc: "We help you establish and manage a strong social media presence, fostering brand engagement and community building.",
    },
    {
      name: "Content Marketing",
      desc: "We create compelling and informative content that attracts your audience, educates them, and positions you as an industry leader.",
    },
    {
      name: "Email Marketing",
      desc: "We craft targeted email marketing campaigns that nurture leads, build relationships, and drive conversions.",
    },
    {
      name: "Marketing Automation",
      desc: "We leverage marketing automation tools to streamline processes, personalize interactions, and improve overall campaign efficiency.",
    },
    {
      name: "Conversion Rate Optimization (CRO)",
      desc: "We help you optimize your website and landing pages to convert more visitors into paying customers.",
    },
    {
      name: "Beyond the Digital Realm",
      desc: "Building Relationships At KARN Marketing Warfare LLP, we believe in fostering strong relationships with our clients. We go beyond transactional interactions and strive to become an extension of your marketing team. We provide open communication, transparent reporting, and regular consultations to ensure you're always in the loop and invested in the process.",
    },
  ];
  return (
    <>
      <Imageslider />

      <div className="flex md:flex-row flex-col-reverse mt-14 xl:mx-24 lg:mx-16 mx-5">
        <div className="mt-5 md:mt-0 w-full md:w-1/2">
          <p className="text-black text-3xl text-left xl:text-7xl">
            We Create a Shining Brand. The Branding Agency
            <span className="text-[#fb923c]"> In Navi Mumbai</span>
          </p>
          <p className="text-left pt-2">
            Branding plays a pivotal role in the growth of businesses
          </p>
          <p className="text-left text-xl pt-2 lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
            A brand desires to be constantly better and tries to be monitored
            very well to excel in this aggressive worldwide. As a part of brand
            advertising, it’s important to build, engage, and persist the logo
            picture inside the consumer’s mind. As a Branding Agency in Navi
            Mumbai, we behavior huge brand research, observe your business, and
            create an interactive logo marketing approach to goal your customers
            on and offline.
          </p>
          <p className="text-left text-xl pt-2 lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
            We’ll examine your enterprise market, recognize competition have a
            look at your brand metrics, and renovate your logo marketing
            strategies for a higher purchaser fee. The task of a branding
            business enterprise is to provide an entire makeover in your
            commercial enterprise from brand layout, and marketing fabric layout
            to the example of the brand digitally.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img src="/images/cam.jpg" alt="" className="h-full" />
        </div>
      </div>

      <p className="mt-14 text-5xl shadow-md bg-[#fb923c] text-white p-14">
        WHAT WE DO
      </p>
      <div className="xl:mx-24 mx-5">
        <div className="flex md:flex-row flex-col-reverse mt-14 xl:mx-24 lg:mx-16 mx-5 ">
          <div className="text-left w-full md:w-1/2 mt-5 md:mt-0">
            <p className="lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              When you choose us as your branding design firm in Navi Mumbai, we
              develop a particularly tailored, thoughtfully designed logo that
              will serve as the proud representation of your business. Are you
              set to design your logo?
            </p>
            <ul className="list-disc pl-5 pt-5 lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              <li> Stellar Quality Design feel</li>
              <li>Unmatched finesse and pictures information</li>
              <li>Unparalleled Brand expertise</li>
              <li>Builds trust: Shows professionalism</li>
            </ul>
          </div>
          <div className="flex justify-end w-full md:w-1/2">
            <div className="blackbox  bg-[#16171B] h-full md:w-2/3 text-white p-10  text-left ">
              <p className="font-bold xl:text-3xl lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                LOGO DESIGN
              </p>
              <p className="pt-5 font-light lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                We claim a brand lasts longer, but some say a mark lasts
                forever.
              </p>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col mt-14 xl:mx-24 lg:mx-16 mx-5 ">
          <div className="flex justify-start w-full md:w-1/2">
            <div className="blackbox bg-[#16171B] h-full md:w-2/3 text-white p-10 text-left ">
              <p className="font-bold xl:text-3xl lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                Packaging design
              </p>
              <p className="pt-5 font-light lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              Packaging uses narrative imagery and personalized text to create a lasting impression on consumers while successfully communicating a brand's values, mission, and USP.
              </p>
            </div>
          </div>
          <div className="text-left w-full md:w-1/2 mt-5 md:mt-0">
            <p className="lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              Packaging allows narrating your emblem story and hooking up with
              clients on a deeper diploma. Incorporate elements that deliver
              your emblem's values, venture, and specific promoting proposition.
            </p>
            <ul className="list-disc pl-5 pt-5 lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              <li>
                {" "}
                Grabs attention: Stands out on shelves, builds brand recognition
              </li>
              <li>
                Tells your story: Connects with emotions, conveys brand values
              </li>
              <li>Boosts convenience: Easy to open, use, & recycle</li>
              <li>Informs & educates: Clear info, builds trust</li>
            </ul>
          </div>
        </div>

        <div className="flex md:flex-row flex-col-reverse mt-14 xl:mx-24 lg:mx-16 mx-5 ">
          <div className="text-left w-full md:w-1/2 mt-5 md:mt-0">
            <p className="lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              We create brochures, flyers, business cards, envelopes, rate
              charts, and more as a full-service branding company. Additionally,
              banners for events, 3D display stalls for events, packaging for
              product manufacturers, and more.
            </p>
            <ul className="list-disc pl-5 pt-5 lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              <li> Results. Highlights speed and achievement</li>
              <li>Effortless. You Win. Emphasizes ease of use and success</li>
              <li>Quality You Trust. Builds trust in product/service.</li>
              <li>More. Less Cost. Focuses on value and saving money.</li>
            </ul>
          </div>
          <div className="flex justify-end w-full md:w-1/2">
            <div className="blackbox bg-[#16171B] h-full md:w-2/3 text-white p-10 text-left ">
              <p className="font-bold lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                Stationary
              </p>
              <p className="pt-5 font-light lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                Another branding collateral is designed to leave a lasting
                impact on the target audience while remaining true to your
                company identity.
              </p>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col mt-14 xl:mx-24 lg:mx-16 mx-5 ">
          <div className="flex justify-start w-full md:w-1/2">
            <div className="blackbox bg-[#16171B] h-full md:w-2/3 text-white p-10 text-left ">
              <p className="font-bold lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                Brand Strategy and Development
              </p>
              <p className="pt-5 font-light lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
                Helping businesses define their unique selling proposition (USP)
                and develop clear, concise messaging that resonates with their
                target audience.
              </p>
            </div>
          </div>
          <div className="text-left w-full md:w-1/2 mt-5 md:mt-0">
            <p className="lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              Crafting a consistent personality for the brand's communication
              style, whether it's playful, authoritative, or something else
              entirely.Researching the competition to understand their strengths
              and weaknesses and identify opportunities for differentiation.
            </p>
            <ul className="list-disc pl-5 pt-5 lg:text-[1.2vw] xl:text-[1.5vw] leading-tight">
              <li> Stand Out: Strong brand cuts thru clutter</li>
              <li>Love Your Brand: Builds loyalty & trust</li>
              <li>Premium Power: Commands higher prices</li>
              <li>Clear Message: Connects with ideal customers</li>
            </ul>
          </div>
        </div>
      </div>
      {/*  */}

      {/*  */}
      <div className="text-left px-5 md:px-20 py-20">
        <div className="text-center border-l-4 px-5 border-blue-500">
          <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
            {/* Our Services */}
            {/* <Typewriter
              options={{
                strings: [" Branding "],
                autoStart: true,
                loop: true,
              }}
            /> */}
            Branding
          </h1>

          <motion.p
            className="text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Marketing Warfare KARN LLP: Managing Your Digital Takeover
          </motion.p>
        </div>
      </div>

      {/*  new section*/}

      <div className="lg:flex  h-full md:px-20 w-full lg:gap-5 pb-9">
        <div className="w-full h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <img
              className="rounded-2xl  w-full object-cover"
              src="https://img.freepik.com/premium-photo/brand-concept-wood-cube-block-with-word-brand-wooden-table-brand-building-success-concept_483511-3780.jpg?w=826"
              alt={"imageAlt"}
            />
          </div>
        </div>
        <div className="lg:w-1/2 w-full  bg-white">
          <div className="bg-[#eef1f2] w-full h-full py-16 px-8  rounded-2xl text-center">
            <h1 className="text-red-900 font-bold lg:text-[1.5vw]">
              We Help You GROW Your BRAND!
            </h1>
            <h1 className="text-red-900 font-bold lg:text-[1.2vw]">
              Grow your business with BRANDING Services
            </h1>
          </div>
        </div>
      </div>

      {/* end new section */}

      {/*  */}
      <div className=" border-black px-5 md:px-20 flex flex-wrap lg:flex-row md:flex-row sm:flex-col text-left">
        <div className="md:w-3/4 w-full p-6 flex flex-col flex-grow flex-shrink">
          <h2 className="md:text-5xl text-xl  mb-4 font-extrabold tracking-widest text-[#fb923c]">
            WE CREATE AWESOME STUFF
          </h2>
        </div>

        {/* second */}
        <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
          <h2 className="text-lg  mb-4  tracking-widest ">
            Your brand identity is what defines you, your passion, and your
            business. Strong emblem signatures and symbols assist in holding
            your organization within the minds of capacity customers. To succeed
            in this competitive global, a symbol desires to usually try for
            excellence and be closely watched. It is important to create,
            interact with, and maintain the logo photograph within the minds of
            consumers as part of brand marketing. As a branding agency in Navi
            Mumbai, we do in-intensity logo analyses, verify your corporation,
            and expand an interactive logo advertising approach to reach
            customers online and stale. We'll study your industry, discover your
            opponents, have a look at your logo records, and replace your emblem
            marketing techniques to attract more premium clients. A branding
            enterprise will cope with the whole thing from brand creation to
            marketing and advertising and marketing material format to virtual
            logo illustration in your enterprise.
          </h2>

       
        </div>
       
      </div>

      {/*  */}

      <div className=" border-black px-5 md:px-20 flex flex-wrap lg:flex-row md:flex-row sm:flex-col text-left">
        <div className=" w-full p-6 flex flex-col flex-grow flex-shrink">
          <h2 className="md:text-5xl text-xl  mb-4 font-extrabold tracking-widest text-[#fb923c]">
            Ready to Join the Digital Battlefield?
          </h2>
        </div>

        {/* second */}
        <div className="w-full p-6 flex flex-col flex-grow flex-shrink">
          <h2 className=" text-lg mb-4  tracking-widest ">
            Contact KARN Marketing Warfare LLP today and let's discuss your
            unique digital marketing needs. We're your trusted partner in
            crafting a winning strategy and conquering the digital world.
            Together, we'll transform your brand into a force to
          </h2>
        </div>
      </div>
    </>
  );
};

export default Branding;
