import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Imageslider from "./component/Imageslider";
import WhatOffering from "./component/WhatOffering";
import { Link } from "react-router-dom";
import SimpleProcess from "./component/SimpleProcess";

const Websitedev = () => {
  return (
    <>
      <Imageslider />

      <div className="flex lg:flex-row flex-col lg:mx-auto px-5 mx-5 md:mx-14 my-20 gap-10">
        <div className="w-full flex justify-center ">
          <img
            src="https://img.freepik.com/premium-photo/young-male-computer-science-freelancer-using-smartphone-desk_662251-1012.jpg?w=740"
            alt=""
            className="rounded-lg  h-full"
          />
        </div>
        <div className="w-full text-left ">
          <h1 className="text-[#0f0f0f] text-5xl font-bold xl:text-[3vw] leading-tight">
            We Build a Website That Converts Visitors into Customers
          </h1>
          <p className="text-[#0f0f0f] text-2xl my-3 xl:text-[1.5vw] leading-tight">
            We create high-performing websites that drive results
          </p>
          <p className="xl:text-[1.5vw] leading-tight">
            We create amazing User-friendly websites in a very good manner to
            not great hold your clients engaged but also preserve them. Websites
            designed and advanced through us will seamlessly scale at some stage
            in most cell gadgets. Whether you are looking for a glowing layout
            or upgrading a gift website, KARN Marketing has the knowledge to
            grow bespoke and placing web websites within a brief period.
          </p>
          <Link to="/contact">
            <button className="p-5 border-2 mt-5 text-[#fb923c] border-[#fb923c] hover:text-white rounded-xl hover:bg-[#fb923c] text-2xl font-bold xl:text-[1.5vw] leading-tight">
              Get a Free Website Quote
            </button>
          </Link>
        </div>
      </div>
      {/* Simple Process */}
      <SimpleProcess />

      {/* Simple Process */}
      <div className="mt-20 w-full mx-auto  bg-[#5956e9] text-white py-10">
        <div className="flex  lg:flex-col flex-col  md:mx-14 mx-5 justify-between lg:gap-10">
          <div className="w-full lg:w-full  flex justify-evenly  my-auto">
            <div>
              <h1 className="capitalize text-5xl  xl:text-[2vw] leading-tight  text-center">
                Creative Web Design Process
              </h1>
              <p className="text-2xl py-5  xl:text-[1.5vw] leading-tight">
                Professional Website Design Agency to provide solutions
              </p>
            </div>
          </div>
          <div className="w-full lg:w-3/4 mx-auto  bg-white ">
            <div className=" flex  ">
              <div className="w-full text-left">
                <div>
                  <div className="w-full  text-left">
                    <motion.div
                      className="p-6  "
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex lg:flex-row flex-col  lg:w-full">
                        <div className="w-full ">
                          <img
                            src="./weblogos/Discovery.png"
                            className=" mx-auto mb-4 rounded-t-lg xl:w-[5vw] xl:h-[10vh] w-24 h-24"
                          />
                        </div>
                        <div className="w-full ">
                          <h2 className="lg:text-left text-center xl:text-[1.5vw]  leading-tight text-xl font-bold mb-2 text-[#ffac32] ">
                            Discovery
                          </h2>
                          <p className="text-[#0079c6] lg:text-left text-center xl:text-[1vw]">
                            This initial phase involves understanding your
                            website's goals, target audience, and the overall
                            purpose.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="w-full  text-left">
                    <motion.div
                      className="p-6  "
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex lg:flex-row flex-col  lg:w-full ">
                        <div className="w-full ">
                          <img
                            src="/weblogos/Planning.png"
                            className="object-cover mx-auto mb-4 rounded-t-lg  xl:w-[5vw] xl:h-[10vh] w-24 h-24"
                          />
                        </div>
                        <div className="w-full ">
                          <h2 className="lg:text-left text-center xl:text-[1.5vw] text-xl font-bold mb-2 text-[#ffac32] ">
                            Planning
                          </h2>
                          <p className="text-[#0079c6] lg:text-left text-center xl:text-[1vw]">
                            This includes sitemaps that outline the website's
                            structure and wireframes that provide a visual idea
                            of how the pages will be laid out.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <motion.div
                      className="p-6 "
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex lg:flex-row flex-col  lg:w-full">
                        <div className="w-full">
                          <img
                            src="/weblogos/Design.png"
                            className="object-cover mx-auto mb-4 rounded-t-lg  xl:w-[5vw] xl:h-[10vh] w-24 h-24"
                          />
                        </div>
                        <div className="w-full ">
                          <h2 className="lg:text-left text-center xl:text-[1.5vw] font-bold mb-2 text-[#ffac32] ">
                            Design
                          </h2>
                          <p className="text-[#0079c6] lg:text-left text-center xl:text-[1vw]">
                            In this stage, the website's visual elements come to
                            life. The developer will design the look and feel of
                            your website.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="w-full text-left">
                <div>
                  <div className="">
                    <motion.div
                      className="p-6 "
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex lg:flex-row flex-col  lg:w-full">
                        <div className="w-full">
                          <img
                            src="/weblogos/Content.png"
                            className="object-cover mx-auto mb-4 rounded-t-lg  xl:w-[5vw] xl:h-[10vh] w-24 h-24"
                          />
                        </div>
                        <div className="w-full ">
                          <h2 className="lg:text-left text-center xl:text-[1.5vw] font-bold mb-2 text-[#ffac32] ">
                            Content Creation
                          </h2>
                          <p className="text-[#0079c6] lg:text-left text-center xl:text-[1vw]">
                            This involves writing the text, captions, and other
                            content that will populate your website.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <motion.div
                      className="p-6 "
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex lg:flex-row flex-col  lg:w-full">
                        <div className="w-full">
                          <img
                            src="/weblogos/Development.png"
                            className="object-cover mx-auto mb-4 rounded-t-lg  xl:w-[5vw] xl:h-[10vh] w-24 h-24"
                          />
                        </div>
                        <div className="w-full ">
                          <h2 className="lg:text-left text-center xl:text-[1.5vw] font-bold mb-2 text-[#ffac32] ">
                            Development
                          </h2>
                          <p className="text-[#0079c6] lg:text-left text-center xl:text-[1vw]">
                            The developer will use coding languages like HTML,
                            CSS, and JavaScript to bring your website to life
                            and make it function according to the plan.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <motion.div
                      className="p-6 "
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex lg:flex-row flex-col  lg:w-full">
                        <div className="w-full ">
                          <img
                            src="/weblogos/Maintenance.png"
                            className="object-cover mx-auto mb-4 rounded-t-lg  xl:w-[5vw] xl:h-[10vh] w-24 h-24"
                          />
                        </div>
                        <div className="w-full ">
                          <h2 className="lg:text-left text-center font-bold mb-2 xl:text-[1.5vw] text-[#ffac32] ">
                            Maintenance
                          </h2>
                          <p className="text-[#0079c6] lg:text-left text-center xl:text-[1vw]">
                            Regular maintenance is crucial to keep your website
                            secure, up-to-date, and functioning smoothly.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  What We’re Offering*/}

      <WhatOffering />

      {/*  What We’re Offering*/}

      {/*Quality web designing  */}
      <div>
        <p className="text-[#0f0f0f] text-5xl font-bold xl:text-[2vw] leading-tight capitalize">
          Why Our Website Design Services Outshine the Rest
        </p>
        <div className="flex lg:flex-row   flex-col lg:mx-auto   px-5 my-10 lg:gap-1 gap-10 text-left">
          <div className="w-full  flex justify-center gap-5 bg-[#ececec] p-5">
            {/* <div className="p-5  xl:text-[1.5vw] leading-tight my-auto md:bg-gray-300 text-[#fb923c] hover:bg-[#fb923c] hover:text-[#fff] font-bold text-xl ">
              01
            </div> */}
            <div className="my-auto">
              <p className="text-2xl font-bold  xl:text-[1.5vw] leading-tight">
              We Speak Your Language

              </p>
              <p className="text-xl  xl:text-[1.5vw] leading-tight">
              Marketing and generation would possibly look like complex landscapes, however we accept this as true within clean communication. 
              </p>
            </div>
          </div>
          <div className="w-full flex justify-center gap-5 bg-[#ececec] p-5">
            {/* <div className="p-5  xl:text-[1.5vw] leading-tight my-auto bg-gray-300 text-[#fb923c] hover:bg-[#fb923c] hover:text-[#fff] font-bold text-xl">
              02
            </div> */}
            <div className="my-auto">
              <p className="text-2xl font-bold  xl:text-[1.5vw] leading-tight">
              Expertise Beyond Code
              </p>
              <p className="text-xl  xl:text-[1.5vw] leading-tight">
              While our team boasts outstanding technical prowess in internet development, our understanding extends ways beyond strains of code
              </p>
            </div>
          </div>
          <div className="w-full flex justify-center gap-5 bg-[#ececec] p-5">
            {/* <div className="p-5  xl:text-[1.5vw] leading-tight my-auto bg-gray-300 text-[#fb923c] hover:bg-[#fb923c] hover:text-[#fff] font-bold text-xl">
              03
            </div> */}
            <div className="my-auto">
              <p className="text-2xl font-bold  xl:text-[1.5vw] leading-tight">
              Proven Track Record of Success
              </p>
              <p className="text-xl  xl:text-[1.5vw] leading-tight">
              Our portfolio speaks for itself. We have a proven track record of creating high-performing websites for diverse industries, driving measurable results for our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*Quality web designing  */}
    </>
  );
};

export default Websitedev;
