import React from "react";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
const Visionmission = () => {
  return (
    <>
      <div className="text-left px-5 md:px-20">
        <div className="text-center pt-16">
          <div className="text-center border-l-4 px-5 border-blue-500">
            <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
              {/* <Typewriter
                options={{
                  strings: [" Vision and Missions"],
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              Vision and Missions
            </h1>

            <motion.h1
              className="capitalize text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Navigating Towards Success: Understanding Vision and Mission
            </motion.h1>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap md:mx-24 px-4 mt-16">
        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 ">
          <img
            className="h-auto max-w-full lg:w-3/4 rounded-2xl"
            src="https://img.freepik.com/free-photo/dartboard-with-arrow-red-background-symbol-setup-business-objective-achievement-target-concept-by-3d-render_616485-93.jpg?t=st=1710157297~exp=1710160897~hmac=ec2bb8d5a237a3826746fa1311fee87cfc87154230c9b8461432eaf62d626b7e&w=826"
            alt="image description"
          />
        </div>
        <div className="w-full  md:w-1/2  mb-4  text-left md:p-5 ">
          <div className="flex gap-5 ">
            <div className="icon bg-green-300 h-1/2 rounded-full p-2 text-white ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                id="check"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"></path>
              </svg>
            </div>
            <div>
              <p className="lg:text-[2vw] text-xl font-bold">Our Vision</p>
              <p className="lg:text-[1.2vw] ">
                To be a catalyst for impactful and sustainable growth in the
                digital landscape, empowering businesses to thrive and connect
                with their audiences in meaningful ways
              </p>
            </div>
          </div>

          <div className="flex gap-5 pt-5 lg:pt-8">
            <div className="icon bg-green-300 h-1/2 rounded-full p-2 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                id="check"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"></path>
              </svg>
            </div>
            <div>
              <p className="lg:text-[2vw] text-xl font-bold">Our Mission</p>
              <p className="lg:text-[1.2vw]">
                To deliver innovative and data-driven digital marketing
                solutions that ignite brand awareness, drive customer
                engagement, and propel measurable results for our clients.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Visionmission;
