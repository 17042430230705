import React from 'react'
import Imageslider from './Imageslider'

const Privacypolicy = () => {
  return (
    <div>
          <Imageslider />
privacy policy
    </div>
  )
}

export default Privacypolicy