import React, { useState } from "react";

const Servicecards = () => {
  const [selectedData, setSelectedData] = useState({
    id: 1,
    title: "BRONZE",
    content: "This is data 1",
    img: "https://cdn-static.farfetch-contents.com/content/images/Home_CMS/Cms/FFAccess/landing_page/bronze-desktop-2x.jpg",
  });

  const data = [
    {
      id: 1,
      title: "BRONZE",
      content: "This is data 1",
      img: "https://cdn-static.farfetch-contents.com/content/images/Home_CMS/Cms/FFAccess/landing_page/bronze-desktop-2x.jpg",
      color: "red",
    },
    {
      id: 2,
      title: "SILVER",
      content: "This is data 2",
      img: "https://cdn-static.farfetch-contents.com/content/images/Home_CMS/Cms/FFAccess/landing_page/silver-desktop-2x.jpg",
      color: "gray",
    },
    {
      id: 3,
      title: "GOLD",
      content: "This is data 3",
      img: "https://cdn-static.farfetch-contents.com/content/images/Home_CMS/Cms/FFAccess/landing_page/gold-desktop-2x.jpg",
      color: "yellow",
    },
    {
      id: 4,
      title: "PLATINUM",
      content: "This is data 4",
      img: "https://cdn-static.farfetch-contents.com/content/images/Home_CMS/Cms/FFAccess/landing_page/platinum-desktop-2x.jpg",
      color: "blue",
    },
    // {
    //   id: 5,
    //   title: "PRIVATE CLIENT",
    //   content: "This is data 4",
    //   img: "https://cdn-static.farfetch-contents.com/content/images/Home_CMS/Cms/FFAccess/landing_page/private-desktop-2x.jpg",
    // },
  ];
  const handleClick = (id) => {
    setSelectedData(data.find((item) => item.id === id));
  };

  return (
    <>
      <div className="">
        <div className="App w-full  pb-10  pt-10">
          {/* flex border-b */}
          <div className=" w-full myline btn-container flex gap-5 justify-center h-full border-gray-200   mx-auto px-40">
            {data.map((item) => (
              <ol className="relative border-s border-gray-200 dark:border-gray-700  ">
                <li   onClick={() => handleClick(item.id)}>
                  <div className="bg-[#04b299] lg:p-20 py-12 px-20 ">
                    <button
                      key={item.id}
                      className={`btn mb-1 ms-1 ${
                        selectedData && selectedData.id === item.id
                          ? "active  text-yellow-900 border-b border-blue-900 bg-${item.color}-500"
                          : ""
                      } text-xl`}
                      // onClick={() => handleClick(item.id)}
                    >
                      <span
                      // className={`absolute flex items-center justify-center w-6 h-6 bg-${item.color}-500 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900`}
                      ></span>
                      <h3 className=" flex items-center mb-1 text-lg font-semibold text-gray-100 dark:text-white">
                        {item.title}
                      </h3>
                    </button>
                  </div>
                </li>
              </ol>
            ))}
          </div>
{/*  */}

{/*  */}
          {selectedData && (
            <div className="data-container">
              {/*  */}
              <div className=" dark:bg-gray-800 py-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex flex-col md:flex-row -mx-4">
                    <div className="md:flex-1 px-4">
                      <div className="h-[460px] rounded-lg bg-gray-300 dark:bg-gray-700 mb-4">
                        <img
                          className="w-full h-full object-cover"
                          src={selectedData.img}
                          alt="Product Image"
                        />
                      </div>
                    </div>
                    <div className="md:flex-1 px-4">
                      <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-2">
                        {selectedData.title}
                      </h2>
                      <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">
                        {selectedData.content}
                      </p>

                      <div>
                        <span className="font-bold text-gray-700 dark:text-gray-300">
                          Product Description:
                        </span>
                        <p className="text-gray-600 dark:text-gray-300 text-sm mt-2">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed sed ante justo. Integer euismod libero id
                          mauris malesuada tincidunt. Vivamus commodo nulla ut
                          lorem rhoncus aliquet. Duis dapibus augue vel ipsum
                          pretium, et venenatis sem blandit. Quisque ut erat
                          vitae nisi ultrices placerat non eget velit. Integer
                          ornare mi sed ipsum lacinia, non sagittis mauris
                          blandit. Morbi fermentum libero vel nisl suscipit, nec
                          tincidunt mi consectetur.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Servicecards;
