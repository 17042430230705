import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Form from "./Form";
import "./styles.css";
import Dropdown from "./Dropdown";

const Navbar = () => {
  const primaryColor = "#2E4053";
  const secondaryColor = "#F6B93B";
  const textColor = "#FFFFFF";
  const accentColor = "#EA4C49";
  const secondaryTextColor = "#28B463";

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState("/");
  const [isServicesDropdownVisible, setIsServicesDropdownVisible] =
    useState(false);
  const dropdownRef = useRef(null);

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible);
  };

  const handleLinkClick = (page) => {
    setCurrentPage(page);
    setIsMenuVisible(false);
  };

  const toggleServicesDropdown = () => {
    console.log(dropdownRef.current);
    setIsServicesDropdownVisible(!isServicesDropdownVisible);
  };

  // scroll bg transperent
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClassName = scrollPosition > 0 ? "bg-white" : "bg-white ";
  const navbarTextClassName =
    scrollPosition > 0 ? "text-gray-800" : "text-gray-800 ";
  const navbarImgSrc =
    scrollPosition > 0
      ? "https://karnllp.files.wordpress.com/2023/10/cropped-karn-logo.png"
      : "https://karnllp.files.wordpress.com/2023/10/cropped-karn-logo.png";
  const navbarImgClass = scrollPosition > 0 ? "h-16 " : "h-16";

  //

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsServicesDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  return (
    <div className={`fixed z-20 w-[100vw] ${navbarClassName} shadow-lg  `}>
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/" onClick={() => handleLinkClick("/")}>
                <img
                  className={`${navbarImgClass} w-auto`}
                  // src="https://karnllp.files.wordpress.com/2023/10/cropped-karn-logo.png"
                  src={`${navbarImgSrc}`}
                  alt="Workflow"
                />
              </Link>
            </div>
            <div className="hidden md:block">
              {/* <div className="ml-10 flex items-baseline space-x-4 ">
                <Link
                  to="/"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/home" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/")}
                >
                  Home
                </Link>

                <Link
                  to="/about"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/about" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/about")}
                >
                  About Us
                </Link>
              
                <Dropdown />
                <Link
                  to="/contact"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/contact" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/contact")}
                >
                  Contact
                </Link>
              </div> */}
               <div className="ml-10 flex items-baseline space-x-4 ">
                <a
                  href="/"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e]  hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/home" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/")}
                >
                  Home
                </a>

                <a
                  href="/about"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/about" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/about")}
                >
                  About Us
                </a>
              
                <Dropdown />
                <a
                  href="/Blog"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/Blog" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/Blog")}
                >
                  Blog
                </a>
                <a
                  href="/FAQ"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/FAQ" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/FAQ")}
                >
                  FAQ
                </a>
                <a
                  href="/contact"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/contact" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/contact")}
                >
                  Contact
                </a>
             
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              type="button"
              className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setIsMenuVisible(!isMenuVisible)}
            >
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <button
                className="bg-[#fb923c] hover:bg-[#fcae6e] text-orange-100 font-bold py-2 px-4 rounded"
                onClick={toggleForm}
              >
                ENQUIRE NOW
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div
        className={`md:hidden ${
          isMenuVisible ? "block" : "hidden"
        } absolute top-16 inset-x-0 transition transform origin-top-right z-10`}
        id="mobile-menu"
      >
        <div className=" rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img
                  className="h-10 w-auto"
                  src="https://karnllp.files.wordpress.com/2023/10/cropped-karn-logo.png"
                  alt="Workflow"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  onClick={() => setIsMenuVisible(!isMenuVisible)}
                >
                  <span className="sr-only">Close main menu</span>

                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav
                className="grid gap-y-8"
                // onClick={() => setIsMenuVisible(!isMenuVisible)}
              >
                <a
                  href="/"
                  className="${navbarTextClassName} hover:bg-blue-200 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Home
                </a>
                <a
                  href="/about"
                 
                  className="${navbarTextClassName} hover:bg-blue-200 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                >
                  About Us
                </a>
                <a
                  href="/Blog"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/Blog" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/Blog")}
                >
                  Blog
                </a>
                <a
                  href="/FAQ"
                  className={`${navbarTextClassName} hover:bg-[#fcae6e] hover:text-white px-3 py-2 rounded-md text-lg font-medium ${
                    currentPage === "/FAQ" ? "bg-[#fb923c] text-white" : ""
                  }`}
                  onClick={() => handleLinkClick("/FAQ")}
                >
                  FAQ
                </a>

                <Dropdown />

                <a
                  href="/contact"
                  className="${navbarTextClassName} hover:bg-blue-200 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Contact
                </a>
               
              </nav>
            </div>
          </div>
          <div className="py-6 px-5">
            <div className="flex items-center justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={toggleForm}
              >
                Toggle Form
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4  sm:px-0">
        {isFormVisible && <Form mycloseform={toggleForm} />}
      </div>
    </div>
  );
};

export default Navbar;