import React from "react";
import { motion, useDragControls } from "framer-motion";
import Typewriter from "typewriter-effect";
import Imageslider from "./component/Imageslider";
import { useTrail, animated } from "react-spring";
import "./App.css";
import Ourservices from "./sections/Ourservices";
import Whyus from "./sections/Whyus";
import Visionmission from "./sections/Visionmission";

const AboutPage = () => {
  const trail = useTrail(2, {
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 1000 },
  });

  return (
    <>
      <Imageslider />

      <div className="  pt-10 ">
        <div className="text-left px-5 md:px-20 ">
          <div className="text-center border-l-4 px-5 border-blue-500">
            <h1 className="text-4xl md:text-3xl font-bold text-blue-900 text-left">
              {/* Our Services */}
              {/* <Typewriter
                options={{
                  strings: [" ABOUT US"],
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              ABOUT US
            </h1>

            <motion.p
              className="text-2xl md:text-xl pt-4 font-bold text-blue-900 text-left"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Combining Of Technology and Marketing
            </motion.p>
          </div>
        </div>

        <Ourservices />
        <div className="justify-center service py-20 ">
          <animated.div className="w-full">
            <div className="px-5 md:px-20 flex flex-wrap lg:flex-row md:flex-row flex-col-reverse text-left">
              <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
                <p className="text-2xl font-bold mb-4 text-[#fb923c] leading-tight md:text-[3vw] ">
                  Your Battlefield Dominators in the Digital Age
                </p>

                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight">
                  We KARN Marketing Warfare LLP (KMWL), your one-stop shop for
                  conquering the digital landscape. We're a passionate bunch of
                  marketing warriors based in the heart of Navi Mumbai, India,
                  with a single mission: to empower businesses like yours to
                  achieve digital dominance. But who are we, and why choose KMWL
                  to lead your digital charge?
                </p>
                <p className="pt-5 text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight"></p>
                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight">
                  KMWL wasn't born in a boardroom but in the trenches of the
                  ever-evolving digital marketing world. We started as a group
                  of individuals, each brimming with creativity, analytical
                  prowess, and a deep yearning to push the boundaries of what's
                  possible online.{" "}
                </p>

                {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
      Learn More
    </button> */}
              </div>
              <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
                <img
                  src="https://img.freepik.com/free-photo/digital-concept-with-skyline_53876-94838.jpg?t=st=1709729712~exp=1709733312~hmac=a0e4c16c98eea1fecf292b44ccdb810d40eb7146ee824b83d6900e8341192875&w=740"
                  alt="Image 1"
                  className="w-full h-full  "
                />
              </div>
            </div>

            {/* second div */}
            <div className="px-5 md:px-20 flex flex-wrap lg:flex-row md:flex-row sm:flex-col text-left">
              <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
                <img
                  src="https://img.freepik.com/premium-photo/casual-colleagues-putting-hands-together_107420-33510.jpg?w=740"
                  alt="Image 2"
                  className="w-full h-full "
                />
              </div>
              <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
                <p className="capitalize text-2xl font-bold mb-4 text-[#fb923c] leading-tight md:text-[3vw]">
                How are we different?
                </p>
                {/* <p className="text-2xl font-bold mb-4 text-green-900 leading-tight md:text-[3vw]">
                 KMWL
                  stands out like a beacon.
                </p> */}

                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">
                    We're Passionate, Not Just Professional:
                  </b>{" "}
                  We don't just clock in and out; we pour our hearts and souls
                  into every project. Your success is our success, and we
                  celebrate it with the same enthusiasm we bring to strategizing
                  and executing your campaigns.
                </p>
                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">
                    Data-Driven Decisions, Not Blind Faith:
                  </b>{" "}
                  We believe in the power of data. Every decision we make is
                  backed by in-depth analysis, allowing us to tailor strategies
                  specifically to your target audience and industry trends.
                </p>

                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">
                    We don't promise the moon we deliver the stars:
                  </b>{" "}
                  We're honest, transparent, and realistic. We won't make empty
                  promises just to secure a contract. We set achievable goals
                  with measurable results, ensuring you see a tangible return on
                  your investment.
                </p>
              </div>
            </div>
            {/* third div */}
            <div className="px-5 md:px-20 flex flex-wrap lg:flex-row md:flex-row flex-col-reverse text-left">
              <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
                <p className="capitalize text-2xl font-bold mb-4 text-[#fb923c] leading-tight md:text-[3vw]">
                Win Your Online Battlefield. <p className="text-center text-xl text-red-900">Here are  the services we <span className="">provide:</span></p>
                </p>

                <p className="text-black lg:text-[1.2vw] xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">
                    Search Engine Optimization (SEO):
                  </b>{" "}
                  We'll help you climb the SERP ladder and put your brand in
                  front of the right audience, organically.
                </p>
                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">
                  Branding:
                  </b>{" "}
                   We’ll transform boring work into an online platform that generates personality and effectively delivers brand narratives.
                </p>
                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">Social Media Marketing:</b>{" "}
                  We'll help you build a thriving online community, engage your
                  audience, and amplify your brand voice.
                </p>

                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">Influencer Marketing:</b> We'll
                   We'll work with top industry influencers to create communities and use Influencer Marketing to achieve exceptional results.

                </p>
                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">PR Management:</b> We'll
                   we’ll bring your story to life, creating a compelling narrative that captures attention and builds lasting relationships.

                </p>

                <p className="text-black md:text-[1.2vw] text-lg xl:text-[1.5vw] leading-tight py-2">
                  <b className=" text-[#077bc6]">
                    Website Design and Development:
                  </b>{" "}
                  We'll build a website that is not just visually appealing but
                  also optimized for conversions and user experience.
                </p>

                {/*  */}
              </div>
              <div className="md:w-1/2 w-full p-6 flex flex-col flex-grow flex-shrink">
                <img
                  src="https://img.freepik.com/premium-photo/businessman-pressing-virtual-button-online_85869-4492.jpg?w=740"
                  alt="Image 1"
                  className="w-full h-full "
                  // className="w-full object-contain object-contain"
                />
              </div>
            </div>
          </animated.div>
        </div>
      </div>
      <Whyus />
    </>
  );
};

export default AboutPage;
