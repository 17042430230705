import React, { useState } from "react";
import Imageslider from "./component/Imageslider";
const Account = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const accordionItems = [
    {
      title: "How long does it take to see the results for my SEO efforts?",
      content:
        "As per the standard process it takes at least first 3 months, for any SEO services to show the fruitful result, whereas the longest duration it takes is 6 months.",
    },
    {
      title: "Would you get me same position in Google and Bing?",
      content:
        "Bing and Google are two different search engines, with its unique algorithms. Google gets more than 90% searches daily. In this case, if we target both search engines we are more likely to rank first on Bing and later on Google.",
    },
    {
      title: "Can I change my SEO Service plan after sign-up?",
      content:
        "Yes, At TG Connect we are flexible with our SEO Packages, you can change your plan (upgrade or downgrade) at any phase of time.",
    },
    {
      title: "Can I change my SEO Service plan after sign-up?",
      content:
        "Yes. We do provide custom SEO services packages based on client’s requirement.",
    },
  ];

  return (
    <>
      <Imageslider />
      <div className=" pt-44 px-10 text-left py-20">
        {accordionItems.map((item, index) => (
          <div key={index}>
            <button
              type="button"
              className="flex justify-between w-full p-5 font-medium text-left text-gray-500 border-b border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
              onClick={() =>
                setExpandedIndex(expandedIndex === index ? null : index)
              }
            >
              <span>{item.title}</span>
              <svg
                data-accordion-icon
                className={`w-6 h-6 shrink-0 rotate-180 transform ${
                  expandedIndex === index ? "rotate-0" : ""
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </button>
            {expandedIndex === index && (
              <div className="p-5 border-b border-gray-200 dark:border-gray-700">
                {item.content}
              </div>
            )}
          </div>
        ))}

        <div className="btn justify-center flex ">
          <button className="bg-[#04b299] mt-10 p-5 text-white font-semibold rounded">
            Know More
          </button>
        </div>
      </div>
    </>
  );
};

export default Account;
