import React from "react";
import Imageslider from "./component/Imageslider";
import { Link } from "react-router-dom";

const Influencer = () => {
  return (
    <>
      <Imageslider />
      <div
        style={{
          backgroundImage:
            "url(https://quomodothemes.website/html/inflanar/img/in-hero-shape.svg)",
          backgroundSize: "cover",
        }}
      >
        <div className="md:px-20  imgbg w-full    overflow-hidden mt-10 pb-10 ">
          <div className="lg:mx-10 lg:flex-row flex flex-col-reverse lg:gap-0 gap-5 lg:flex ">
            <div className=" lg:w-1/2  mx-5">
              <p className="mt-2 text-4xl leading-tight  text-center md:text-left font-extrabold text-[#333333]">
                Reach New Audiences & Boost Engagement With{" "}
                <span className="text-[#fb923c]"> Influencer Marketing</span>{" "}
                <br />
              </p>
              <p className="text-2xl text-center md:text-left">
                We craft strategic influencer campaigns that drive results.
              </p>
              <p className="text-center md:text-left">
                Influencer marketing has become a major player in the marketing
                world, and for good reason.
              </p>

              <div className=" w-full flex justify-center md:justify-start">
                <Link to="/contact">
                  <button
                    className="
              items-center  p-5 
              border-2 mt-5 text-[#fb923c] 
              border-[#fb923c] hover:text-white rounded-xl 
              hover:bg-[#fb923c]  text-xl font-bold
              "
                  >
                    Get Your Free Consultation
                  </button>
                </Link>
              </div>
            </div>
            <div className="lg:w-1/2 mx-auto h-full ">
              <img
                className="px-5"
                src="./images/man.png"
                alt="Modern building architecture"
              />
            </div>
          </div>
        </div>
        <div
          className="   mx-5 rounded-2xl"
          style={{
            backgroundImage:
              "url(https://quomodothemes.website/html/inflanar/img/st-features-bg.svg)",
            backgroundSize: "cover",
          }}
        >
          <div className="bg-white md:bg-transparent p-10">
            <p className="text-5xl">Why</p>
            <p className="text-4xl font-bold my-5">Influencer Marketing</p>
            <div className="flex md:flex-row flex-col mt-8 gap-10 md:gap-0">
              <div className="w-full md:w-1/2 mx-5 ">
                <div className="  flex justify-center  my-5">
                  <img
                    src="https://quomodothemes.website/html/inflanar/img/st-feature-icon1.svg"
                    alt=""
                    className="bg-[#fe2c55] p-2 rounded-md"
                  />
                </div>
                <div className="text-center ">
                  <p className="text-2xl font-bold text-center">
                    Trust and Credibility:
                  </p>
                  <p className="text-xl ">
                    People are bombarded with ads everywhere they look. They've
                    grown skeptical of traditional marketing messages.
                    Influencers, however, have built trust with their audience.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 mx-5">
                <div className=" flex justify-center my-5">
                  <img
                    src="https://quomodothemes.website/html/inflanar/img/st-feature-icon2.svg"
                    alt=""
                    className="bg-[#1778f2] p-2 rounded-md"
                  />
                </div>
                <div className="text-center">
                  <p className="text-2xl font-bold">Targeted Reach</p>
                  <p className="text-xl ">
                    Influencers have cultivated audiences with specific
                    interests. This allows brands to target a niche market that
                    is more likely to be interested in what they're offering.
                  </p>
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className=" flex justify-center my-5">
                  <img
                    src="https://quomodothemes.website/html/inflanar/img/st-feature-icon3.svg"
                    alt=""
                    className="bg-[#c83ede] p-2 rounded-md"
                  />
                </div>
                <div className="text-center">
                  <p className="text-2xl font-bold">Engagement Power</p>
                  <p className="text-xl ">
                    Influencers don't just broadcast messages; they create
                    conversations. Their content gets shared, liked, and
                    commented on, leading to higher engagement than traditional
                    marketing campaigns.
                  </p>
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className=" flex justify-center my-5">
                  <img
                    src="https://quomodothemes.website/html/inflanar/img/st-feature-icon4.svg"
                    alt=""
                    className="bg-[#ffbb0b] p-2 rounded-md"
                  />
                </div>
                <div className="ttext-center">
                  <p className="text-2xl font-bold">Brand Awareness</p>
                  <p className="text-xl ">
                    Partnering with a well-established influencer can
                    significantly boost brand awareness. Their audience gets
                    exposed to the brand in a natural way, increasing brand
                    recognition and familiarity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <div className="   mx-5 rounded-2xl">
          <div className="bg-white md:bg-transparent p-10">
            <p className="text-5xl ">Working Process</p>
            <p className="text-4xl font-bold my-5 capitalize">What we do?</p>
            <div className="flex md:flex-row flex-col mt-8 gap-10 md:gap-0">
              <div className="w-full md:w-1/2 mx-5 ">
                <div className="  ">
                  <img
                    src="https://img.freepik.com/free-vector/discarded-idea-concept-illustration_114360-8093.jpg?t=st=1710938762~exp=1710942362~hmac=14862e1329e9ee128537f1b63695b3bf826689e0d885c5f2f690968f5b420765&w=826"
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 mx-5">
                <div className=" text-left ">
                  <p className="text-2xl font-bold">Competitors Analysis</p>
                  <p>
                  We examine clients’ competition to make you greater appealing and engageable              
in Your advertising and marketing campaign for customers or clients.

                  </p>
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className="  ">
                  <img
                    src="https://img.freepik.com/free-vector/discarded-idea-concept-illustration_114360-8093.jpg?t=st=1710938762~exp=1710942362~hmac=14862e1329e9ee128537f1b63695b3bf826689e0d885c5f2f690968f5b420765&w=826"
                    alt=""
                  />
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className=" text-left ">
                  <p className="text-2xl font-bold ">Finding Perfect Influencers</p>
                  <p>
                  We find the right social media influencers in your commercial enterprise/products or services to narrate for your niece to get natural and relevant leads in your business.

                  </p>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col mt-8 gap-10 md:gap-0">
              <div className="w-full md:w-1/2 mx-5 ">
                <div className="  ">
                  <img
                    src="https://img.freepik.com/free-vector/discarded-idea-concept-illustration_114360-8093.jpg?t=st=1710938762~exp=1710942362~hmac=14862e1329e9ee128537f1b63695b3bf826689e0d885c5f2f690968f5b420765&w=826"
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 mx-5">
                <div className=" text-left ">
                  <p className="text-2xl font-bold">Develop a campaign strategy.
</p>
                  <p>
                  We create the right advertising advertising marketing campaign method for your advertising and marketing and marketing advertising and marketing campaign to get greater leads from the influencer advertising and marketing and advertising advertising and marketing campaign.

                  </p>
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className="  ">
                  <img
                    src="https://img.freepik.com/free-vector/discarded-idea-concept-illustration_114360-8093.jpg?t=st=1710938762~exp=1710942362~hmac=14862e1329e9ee128537f1b63695b3bf826689e0d885c5f2f690968f5b420765&w=826"
                    alt=""
                  />
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className=" text-left ">
                  <p className="text-2xl font-bold ">Analysis & Reporting for Campaigns
</p>
                  <p>
                  We additionally examine campaigns and file to our customers in mind approximately the following advertising and marketing advertising and marketing campaigns. In addition to showing each campaign’s recognition.


                  </p>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col mt-8 gap-10 md:gap-0">
              <div className="w-full md:w-1/2 mx-5 ">
                <div className="  ">
                  <img
                    src="https://img.freepik.com/free-vector/discarded-idea-concept-illustration_114360-8093.jpg?t=st=1710938762~exp=1710942362~hmac=14862e1329e9ee128537f1b63695b3bf826689e0d885c5f2f690968f5b420765&w=826"
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 mx-5">
                <div className=" text-left ">
                  <p className="text-2xl font-bold">Quantify Metric
</p>
                  <p>
                  We furthermore fundamental influencer Matrics on your advertising and marketing marketing campaign to get greater organic leads to your enterprise.

                  </p>
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className="  ">
                  <img
                    src="https://img.freepik.com/free-vector/discarded-idea-concept-illustration_114360-8093.jpg?t=st=1710938762~exp=1710942362~hmac=14862e1329e9ee128537f1b63695b3bf826689e0d885c5f2f690968f5b420765&w=826"
                    alt=""
                  />
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2 mx-5">
                <div className=" text-left ">
                  <p className="text-2xl font-bold ">Develop a Relationship</p>
                  <p>
                  We furthermore assemble relationships among our agency and influencers for destiny cooperation or advertising and advertising and marketing campaigns.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Influencer;
