import React from "react";

const Maindirection = () => {
  return (
    <>
      <div
        className="w-full  justify-center items-center flex "
        style={{
          background: "linear-gradient(to top, #68b4df, #194173)",
        }}
      >
        <div className=" md:mx-24 mx-5   ">
          <div className="mt-20">
            <p className="text-white ">Work on the result</p>
            <h2 className=" font-thin mb-2 text-white text-3xl">
              Main directions
            </h2>
          </div>
          <div className="flex md:flex-row flex-col my-8 p-5">
            <div className="flex-1  text-white p-4">
              <img
                src="https://ck01708.tmweb.ru/demo/ultrasocial/images/directions/d1.png"
                alt=""
              />
            </div>
            <div className="flex-1  text-white p-4 text-left">
              <h1 className="my-5 font-thin text-3xl lg:text-[1.2vw]">
                Analysis and research
              </h1>
              <p className="font-thin text-lg lg:text-[1vw]">
                Research helps you understand the social media landscape before
                you dive in, and analysis helps you measure and improve your
                efforts after you launch your campaigns. By combining these two
                elements, you can develop data-driven social media marketing
                strategies that deliver real result
              </p>
            </div>
          </div>
          <hr />
          <div className="flex md:flex-row flex-col-reverse my-8 p-5 text-left">
            <div className="flex-1  text-white p-4">
              <h1 className="my-5 font-thin text-3xl lg:text-[1.2vw]">
                Support and promotion
              </h1>

              <p className="font-thin text-lg lg:text-[1vw]">
                Think of support and promotion as a two-way street. By providing
                excellent social media support, you'll build brand loyalty and
                trust, which can lead to organic promotion from satisfied
                customers. Likewise, effective promotion can attract new
                followers who you can then convert into loyal customers through
                social media support.
              </p>
            </div>

            <div className="flex-1  text-white p-4">
              <img
                src="https://ck01708.tmweb.ru/demo/ultrasocial/images/directions/d2.png"
                alt=""
              />
            </div>
          </div>
          <hr />
          <div className="flex md:flex-row flex-col my-8 p-5 text-left">
            <div className="flex-1  text-white p-4">
              <img
                src="https://ck01708.tmweb.ru/demo/ultrasocial/images/directions/d3.png"
                alt=""
              />
            </div>
            <div className="flex-1  text-white p-4">
              <h1 className="my-5 font-thin text-3xl lg:text-[1.2vw]">
                Creative and design
              </h1>

              <p className="font-thin text-lg lg:text-[1vw]">
                By combining strong design with creative concepts, you can
                create social media content that cuts through the noise, grabs
                attention, and achieves your marketing goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maindirection;
