import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

// const settings2 = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   autoplay: true,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 640,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

const settings2 = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1890,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const backgroundImageStyle = {
  backgroundImage: `url(https://img.freepik.com/free-photo/toy-bricks-table-with-word-blog_144627-47465.jpg?t=st=1710339674~exp=1710343274~hmac=8db178857f74d0a0377baa74b30c05b93174bb4f037693b85dc6d26b1bacf2a8&w=1380)`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
  padding: "2rem",
};

const Newcard = () => {
  const testimonials = [
    {
      path: "/Branding",
      id: 1,
      name: "Branding",
      quote:
        "Branding is shaping how people perceive your business. It's more than a logo; it's your values, voice, and customer experience. Strong branding fosters trust and loyalty, making you stand out in the market.",
      image: "/branding.jpg",
    },
    {
      path: "/SEO",
      id: 2,
      name: "Search Engine Optimization",
      quote:
        "SEO is the process used to optimize a website's technical configuration, content relevance, and link popularity so its pages can become easily findable,",
      image: "/seo.jpg",
    },
    {
      path: "/media",
      id: 3,
      name: "Social Media Marketing",
      quote:
        "Interact with your followers on the networks they use to communicate. We'll develop targeted social media campaigns that build brand loyalty, drive website traffic, and generate leads.",
      image: "/Social.jpg",
    },
    {
      path: "/influencer_marketing",
      id: 4,
      name: "Influencer Marketing",
      quote:
        "Influencer marketing leverages trusted online personalities to promote products to their engaged audience, fostering brand awareness and potentially boosting sales.",
      image: "/Influencer.jpg",
    },
    {
      path: "/PR",
      id: 5,
      name: "PR Management",
      quote:
        "Public relations management builds relationships with the public through strategic communication. It aims to create a positive brand image, maintain trust, and influence public perception.",
      image: "/Public.jpg",
    },
  ];

  return (
    <div className=" w-[88vw] md:w-full ">
      <Slider {...settings2} className="slider  md:mx-7 ">
        {testimonials.map((e, i) => {
          return (
            <div className="w-full  ">
              <div className="">
                <div className="card1 ">
                  <div className="header ">
                    <div className="object-cover w-full   rounded-t-lg  ">
                      <img
                        src={e.image}
                        className="object-cover w-full   rounded-t-lg  h-[50vh]"
                      />
                    </div>
                  </div>
                  <p className="text-xl xl:text-2xl font-bold my-2 text-[#eb754d] text-center ">
                    {e.name}
                  </p>
                  <p className="text-[#0079c6] mycardtext text-left xl:text-xl">{e.quote}</p>
                <div className="mt-5 md:mt-0">
                <button
                    class="mt-7 p-3 relative inline-flex items-center justify-center   overflow-hidden 
                     font-medium text-gray-900 rounded-lg 
                    "
                  >
                    {/* <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"> */}
                    <Link
                      to={e.path}
                      className="uppercase hover:bg-[#eb754d] hover:text-white p-4  rounded-xl font-bold text-[#eb754d] border border-[#eb754d]"
                    >
                      Know More
                    </Link>
                    {/* </span> */}
                  </button>
                </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Newcard;
